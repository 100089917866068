//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------- 
// Dependencies
import React from "react";
import { Col, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import backgroundLogin from "../../images/bg-img.png";
import marciLogin from "../../images/bg-login.png";
import LoginForm from "../../components/proyect1/auth/LoginForm";
import {dicctionary} from '../../constants/dictionnary';

interface Props {
  setUser: (user: boolean) => void;
}


const { Text } = Typography;
// Component
const LoginScreen: React.FC<Props> = ({setUser}) => {
  return (
    <Content style={{ height: "100%", width: "100%", }} >
      <img style={{width:"100vw", height:"100vh", position:"absolute", zIndex:0}} src={backgroundLogin} alt={backgroundLogin} />
      <Row style={{height:'95%',display:'flex',alignItems:'center', justifyContent:"center", position: "relative", flexDirection:"row"}}>
        <Col style={{ height: "100vh", width:"50vw", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <Col style={{ height: "70vh", width:"40vw", flexDirection:"column", display:"flex", justifyContent:"center",}}>
            <Text style={{fontSize:"7vh", marginTop:"20vh"}}>{dicctionary.title_login_1.en}</Text>
            <Text  style={{fontSize:"7vh", color:"white", fontWeight:"bold" }}>{dicctionary.title_login_2.en}</Text>
            <Text style={{fontSize:"3vh", color:"white", }}>{dicctionary.title_login_3.en}</Text>
          </Col>
        </Col>
        <Col style={{ height: "100vh", width:"50vw", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <img style={{height: "90vh", width:"45vw", position:"absolute", zIndex:1}} src={marciLogin} alt={marciLogin} />
          <Col style={{ height: "68vh", width:"35vw", zIndex:2}}>
           
            <LoginForm setUser={setUser}/>
          </Col>
        </Col>

      </Row>
      
    </Content>
  );
};

// Export
export default LoginScreen;

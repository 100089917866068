//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React, {useState} from "react";
import { Button, Input,  Col, Row,Form, Checkbox, Typography, Modal  } from "antd";
// import "antd/dist/antd.css";
import iconIRGT from "../../../images/Logo-irgt.png";
import { useNavigate } from "react-router-dom";
import firebase from "../../../firebase/config";
import { uid } from "uid";
import "../../../styles/styles.css"
import {dicctionary} from '../../../constants/dictionnary'

interface Props {
  setUser: (user: boolean) => void;
}



const LoginForm2: React.FC<Props> = ({setUser}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("")
  const [fullName, setFullName] = useState("")
  const [company, setCompany] = useState("")
  const [checkPrivacy, setCheckPrivacy] = useState(false)
  const { Text } = Typography;
  const [openPdf, setOpenPdf] = useState(false);


  const onClickLogin = async(languaje:string) => {
    // Esta función se ejecutará cuando se envíe el formulario
    if (email.trim() ==="" || fullName.trim() ==="" || company.trim() ==="" || checkPrivacy === false) {
      alert("Champs vides!");
      return
    }
    const idNewUser = uid(24)
    await firebase.firestore().collection("sneakPeek").where("email", "==", email).get().then((doc) => {
      if (doc.docs.length === 0 ) {
        firebase.firestore().collection("sneakPeek").doc(idNewUser).set({
          email:email,
          fullName:fullName,
          company:company,
          visitCounter:1,
          privacyAccepted: checkPrivacy,
          id:idNewUser
        }).then(() => {
          setUser(true)
          navigate(`/candidates_fr`)
        })
      }else{
        doc.docs.forEach((item) => {
          firebase.firestore().collection("sneakPeek").doc(item.data().id).update({
            visitCounter:item.data().visitCounter+1
          }).then(() => {
            setUser(true)
            navigate(`/candidates_fr`)
          })
        })
      }
    })
  };

 
  return (
    <Col className="containerLoginForm" style={{zIndex:2}}>
      <div style={{ height: "30%", display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", marginTop:"3%"}}>
        <img src={iconIRGT} style={{ height: "100%", width: "100%",  objectFit:"contain"}} alt={iconIRGT} />
      </div>

      <Modal  
        width={"70vw"}
        centered
        open={openPdf}

        closable={false} 
        footer={[
        <button style={{
            backgroundColor:"#1890ff", 
            borderColor: "#ffffff", 
            color:"white", 
            fontSize: 16,
            borderRadius: 5,
            paddingRight: 20,
            paddingTop: 3,
            paddingBottom: 3,
            paddingLeft: 20,
      
            }} 
            key="ok" onClick={() => {setOpenPdf(false)}}
            >OK
        </button>,
        ]}
        >
        <div className="contenedor" style={{width:"100%", height:"80vh", overflow:"scroll"}}>
          <img src={iconIRGT} alt="" style={{height:"10vh"}}/>
            
        </div>
      </Modal>
      <div className="contenedor" style={{ width: "35vw", height: "45vh", padding:"1vh",overflow:"auto",  display:"flex" ,alignItems:"center",flexDirection:"column"}}>
        
        <Form size="large">
          <div style={{ width:"100%", height:"100%"}}>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Vérifiez votre e-mail",
                },
                {
                  required: true,
                  message: "Vérifiez votre e-mail",
                },
              ]}
            >
              <Input onChange={(e) => setEmail(e.target.value)} type="email" style={{ marginTop:"0.5vh", width: "25vw", height:"5vh", fontSize:"3vh" }} placeholder={dicctionary.email_placeholder_login.fr} />    
            </Form.Item>

            <Form.Item
              name="Full Name"
              rules={[{ 
                required: true, 
                message:"Nom complet vide" 
              },]}
              style={{ fontSize: "20vh" }}
            >
              <Input onChange={(e) => setFullName(e.target.value)} style={{ marginTop:"0.5vh",  width: "25vw", height:"5vh", fontSize:"3vh" }} placeholder={dicctionary.full_name_placeholder_login.fr} />
            </Form.Item>

            <Form.Item
              name="Company"
              rules={[
                { 
                  required: true, 
                  message:"Nom de l'entreprise vide" 
                },
              ]}
            >
              <Input onChange={(e) => setCompany(e.target.value)} style={{ marginTop:"0.5vh",  width: "25vw", height:"5vh", fontSize:"3vh" }} placeholder={dicctionary.name_company_placeholder_login.fr} />
            </Form.Item>
            <Form.Item
              name="Check"
              rules={[
                { 
                  required: true, 
                  message:"Conditions d'utilisation non acceptées" 
                },
              ]}
            >
              <div style={{ marginTop:"0.5vh",  width: "25vw", height:"5vh" }}>
                <Checkbox checked={checkPrivacy} onChange={(e) => setCheckPrivacy(e.target.checked)}></Checkbox>
                <Text onClick={() => setOpenPdf(true)} style={{marginLeft:"1vw", fontSize:"2vh", textDecoration:"underline", color:"#0000ff", cursor:"pointer"}}>{dicctionary.conditions.fr}</Text>
              </div>
            </Form.Item>
          </div>
        </Form> 
      </div>


      <div style={{width:"100%", height:"15%", justifyContent:"center", display:"flex", position:"absolute", top:"90%"}}>
                
        <Button
          type="primary"
          color="#404193"
          style={{
            width: "70%",
            backgroundColor: "#404193",
            justifyContent:"center", 
            alignItems:"center", 
            display:"flex",
            fontSize: "4vh",
            height: "90%",
            borderBottomLeftRadius: "3vh",
            borderBottomRightRadius: "3vh",
            borderTopLeftRadius:0,
            borderTopRightRadius:0,
          }}
          // onClick={() => navigate("/candidates")}
          onClick={() => onClickLogin("fr")}
        >
          {dicctionary.button_login.fr}
        </Button>
      </div>
      <div style={{width:"100%", height:"10%", justifyContent:"center", display:"flex", position:"absolute", top:"110%"}}>
                
       <Row style={{width:'100%',justifyContent:'center', height:"10%"}}>
          <Col >
            {/* <Text style={{fontSize:18,cursor:'pointer'}} >{language ==='English' ? 'Français':'English'}</Text>    */}
          </Col>                
        </Row>
      </div>
    </Col>
  );
};

// Exports
export default LoginForm2;

export const dicctionary = {

    button_login:{
        en:'View profiles',
        fr:'Consultez les profils'
    },
    conditions:{
        en:'I agree to receive communications from Career Swipe',
        fr:"J'accepte de recevoir des communications de Career Swipe"
    },
    email_placeholder_login:{
        en:'Email',
        fr:'Courriel'
    },
    full_name_placeholder_login:{
        en:'Full name',
        fr:'Nom complet'
    },
    name_company_placeholder_login:{
        en:'Name company',
        fr:"Nom de l'entreprise"
    },
    title_login_1:{
        en:'Are you ready to',
        fr:'Est-vous prêt à'
    },
    title_login_2:{
        en:'To take a look at?',
        fr:"Jeter un coup d'œil?"  
    },
    title_login_3:{
        en:'Welcome to Career Swipe, where attraction and matching go hand in hand.',
        fr:"Bienvenue à Career Swipe, où attraction et matching vont de pair."
    },
    experience:{
        en:'Experience',
        fr:"Expérience"
    },
    education:{
        en:'Education',
        fr:"Education"
    },
    skills:{
        en:'Skills',
        fr:"Compétences"
    }



}
import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useEffect, useRef, useState } from 'react'
import { Button, Carousel, Typography, } from 'antd';
import "../../../styles/styles.css"
import icoWork from '../../../images/ico-work.png'
import icoEducation from '../../../images/ico-education.png'
import icoSkill from '../../../images/ico_skills.png'
import rowLeft from '../../../images/btn-left.png'
import rowRight from '../../../images/btn-right.png'
import btnAccept from '../../../images/btn-accept.png'
import btnDecline from '../../../images/btn-decline.png'
import pricing1 from '../../../images/icon-pricing1.png'
import pricing2 from '../../../images/icon-pricing2.png'
import pricing3 from '../../../images/icon-pricing3.png'
import iconYoutube from '../../../images/yt_icon_rgb.png'
import iconLinkeding from '../../../images/LI-In-Bug.png'
import backgroundLogin from '../../../images/backgroundLogin.png'
import { dicctionary } from '../../../constants/dictionnary';
import { candidate_data } from '../../../constants/data_en';
import View from "../../../images/view.png"
import { useParams } from 'react-router-dom'
import logoHorizontal from '../../../images/logo-horizontal.png';

const { Text } = Typography;
// Component

const CandidateView = () => {

    const { id } = useParams()
    const carouselRef = useRef<any>(null);
/*     const [candidate, setCandidate] = useState<any>({})


    useEffect(() => {

        let data = candidate_data.filter(i => i.id === id)[0];
        console.log(data)
        setCandidate(data)
    }, []) */




    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    const handlePrev = () => {
        if (carouselRef.current) {
            carouselRef.current.prev();
        }
    };

    const downloadPDF = () => {
        const rutaPDF = 'https://firebasestorage.googleapis.com/v0/b/jobmatch-e66b9.appspot.com/o/PDF%2FLesServicesCareerSwipe2023.pdf?alt=media&token=049a5ff4-df0a-4d9f-a8b9-4260a1017803&_gl=1*3nfp6f*_ga*MjAyMDg3OTc5Mi4xNjM1NzEwMzQw*_ga_CW55HF8NVT*MTY5OTQ2MTI0MC4xNi4xLjE.*';

        // Realiza una solicitud para obtener el archivo PDF
        fetch(rutaPDF)
            .then((response) => response.blob())
            .then((blob) => {
                // Crea una URL a partir del Blob
                const url = URL.createObjectURL(blob);

                // Crea un enlace oculto y dispara un clic para descargar el archivo
                const enlace = document.createElement('a');
                enlace.href = url;
                enlace.target = '_blank';
                enlace.download = 'Les Services Career Swipe 2023.pdf';
                enlace.style.display = 'none';
                document.body.appendChild(enlace);
                enlace.click();
                document.body.removeChild(enlace);
            });
    };
    const [currentPage, setCurrentPage] = useState(1);
    const handleAfterChange = (current: any) => {
        setCurrentPage(current + 1);
    };

    return (
        <div className='containerRow' style={{ display: 'flex', alignItems: 'center' }}>


            <Button
                className='buttonPrevPag'
                onClick={() => handlePrev()}
            >
                <Text style={{ fontSize: "5vh", color: 'white' }}>
                    <img className='iconRowStyle' src={rowLeft} alt={rowLeft} />
                </Text>
            </Button>
            <Carousel dots={false} className='containerViewsInCarousel' style={{ overflow: "hidden", minWidth: 1200 }} ref={carouselRef} afterChange={handleAfterChange}>
                {/* View 1 */}
               
                <div className='p-24'>
                <div className='flex flex-row justify-center'>
                    <img className='object-contain h-48 w-96' src={logoHorizontal} alt="" />
                </div>
                    <p className='text-4xl font-semibold'>TOP TALENT IS HARD TO COME BY!</p>
                    <p className='text-lg mt-5'>Career Swipe is a Sourcing Service powered with AI for employers looking for qualified candidates without spending time posting or searching.</p>
                    <ul className='mt-5 list-disc'>
                        <li className='mt-1 text-lg font-bold ml-10 ' > Receive qualified applications</li>
                        <li className='mt-1 text-lg font-bold ml-10 ' > Hire faster</li>
                        <li className='mt-1 text-lg font-bold ml-10 ' > Keep growing</li>
                    </ul>
                    <p className='text-lg mt-5'>Career Swipe offers you an innovative solution between passive posting and an expensive recruitment service.</p>

                    <p className='text-lg mt-5'>A flexible and simple process to meet the candidates you want.</p>

                    <p className='text-lg mt-5'>From automated research of the best candidates to providing real-time labor market information, Career Swipe works to make your recruitment process a success.</p>

                </div>
                {/* View 2 */}
                <div className=' mt-40 ml-48'>
                    <img className='object-fill w-100 h-100' src={View} alt={View} />
                </div>
                {/* View 3 */}
                {Object.keys(candidate_data).length > 0 && <div className='container mx-auto p-10'>
                    <div className='flex flex-col'>
                        <div className='flex flex-row'>
                            <div className="flex-none w-7/12 h-auto">
                                <div className='flex flex-col'>
                                    <div className='flex flex-row space-x-8 justify-start items-center h-10'>
                                        <img className='w-10 h-10' src={icoWork} alt={icoWork} />
                                        <h2 className='text-2xl text-center text-cyan-500'>{dicctionary.experience.en}</h2>
                                    </div>
                                    {candidate_data[0].experience.map((i: { position: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; company: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; position_desc: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; date: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) => (
                                        <div className='flex flex-row justify-between my-5'>
                                            <div className='flex flex-col w-7/12'>
                                                <span className='mb-2 font-bold'>{i.position} | {i.company}</span>
                                                <span>{i.position_desc}</span>
                                            </div>
                                            <div className='flex flex-col'>
                                                <span className='mb-2 font-bold'>{i.date}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='flex flex-col w-5/12 ml-5'>
                                <div className='flex flex-row space-x-8 justify-start items-center h-10'>
                                    <img className='w-10 h-10' src={icoEducation} alt={icoEducation} />
                                    <h2 className='text-2xl text-center text-cyan-500'>{dicctionary.education.en}</h2>
                                </div>
                                {candidate_data[0].education.map((i: { major: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; career: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; university: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; date: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) => (
                                    <div className='flex flex-row justify-evenly my-5'>
                                        <span className='mb-2 font-normal'>{i.major}</span>
                                        <span className='mb-2 font-normal'>|  {i.career} </span>
                                        <span className='mb-2 font-normal'>|  {i.university} </span>
                                        <span className='mb-2 font-normal'>|  {i.date}</span>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        <div className='grid grid-row-2 mt-10'>
                            <div className='flex flex-row space-x-8 justify-start items-center h-10'>
                                <img className='w-10 h-10' src={icoSkill} alt={icoSkill} />
                                <h2 className='text-2xl text-center text-cyan-500'>{dicctionary.skills.en}</h2>
                            </div>
                            <div className='flex flex-row justify-evenly my-5'>
                                {candidate_data[0].skills.map((i: { skill: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; specialization: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) => (
                                    <span className='mb-2 text-xl font-semibold'>{i.skill} = {i.specialization}</span>
                                ))}
                            </div>
                        </div>
                        <div className='grid grid-row-2 mt-40'>
                            <div className='flex flex-row space-x-8 justify-center items-center h-10'>
                                <img className='w-40 h-20' src={btnAccept} alt={icoSkill} />
                                <img className='w-40 h-20' src={btnDecline} alt={icoSkill} />
                            </div>
                        </div>
                    </div>
                </div>}
                {Object.keys(candidate_data).length > 0 && <div className='container mx-auto p-10'>
                    <div className='flex flex-col'>
                        <div className='flex flex-row'>
                            <div className="flex-none w-7/12 h-auto">
                                <div className='flex flex-col'>
                                    <div className='flex flex-row space-x-8 justify-start items-center h-10'>
                                        <img className='w-10 h-10' src={icoWork} alt={icoWork} />
                                        <h2 className='text-2xl text-center text-cyan-500'>{dicctionary.experience.en}</h2>
                                    </div>
                                    {candidate_data[1].experience.map((i: { position: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; company: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; position_desc: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; date: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) => (
                                        <div className='flex flex-row justify-between my-5'>
                                            <div className='flex flex-col w-7/12'>
                                                <span className='mb-2 font-bold'>{i.position} | {i.company}</span>
                                                <span>{i.position_desc}</span>
                                            </div>
                                            <div className='flex flex-col'>
                                                <span className='mb-2 font-bold'>{i.date}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='flex flex-col w-5/12 ml-5'>
                                <div className='flex flex-row space-x-8 justify-start items-center h-10'>
                                    <img className='w-10 h-10' src={icoEducation} alt={icoEducation} />
                                    <h2 className='text-2xl text-center text-cyan-500'>{dicctionary.education.en}</h2>
                                </div>
                                {candidate_data[1].education.map((i: { major: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; career: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; university: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; date: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) => (
                                    <div className='flex flex-row justify-evenly my-5'>
                                        <span className='mb-2 font-normal'>{i.major}</span>
                                        <span className='mb-2 font-normal'>|  {i.career} </span>
                                        <span className='mb-2 font-normal'>|  {i.university} </span>
                                        <span className='mb-2 font-normal'>|  {i.date}</span>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        <div className='grid grid-row-2 mt-10'>
                            <div className='flex flex-row space-x-8 justify-start items-center h-10'>
                                <img className='w-10 h-10' src={icoSkill} alt={icoSkill} />
                                <h2 className='text-2xl text-center text-cyan-500'>{dicctionary.skills.en}</h2>
                            </div>
                            <div className='flex flex-row justify-evenly my-5'>
                                {candidate_data[1].skills.map((i: { skill: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; specialization: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) => (
                                    <span className='mb-2 text-xl font-semibold'>{i.skill} = {i.specialization}</span>
                                ))}
                            </div>
                        </div>
                        <div className='grid grid-row-2 mt-40'>
                            <div className='flex flex-row space-x-8 justify-center items-center h-10'>
                                <img className='w-40 h-20' src={btnAccept} alt={icoSkill} />
                                <img className='w-40 h-20' src={btnDecline} alt={icoSkill} />
                            </div>
                        </div>
                    </div>
                </div>}
                {Object.keys(candidate_data).length > 0 && <div className='container mx-auto p-10'>
                    <div className='flex flex-col'>
                        <div className='flex flex-row'>
                            <div className="flex-none w-7/12 h-auto">
                                <div className='flex flex-col'>
                                    <div className='flex flex-row space-x-8 justify-start items-center h-10'>
                                        <img className='w-10 h-10' src={icoWork} alt={icoWork} />
                                        <h2 className='text-2xl text-center text-cyan-500'>{dicctionary.experience.en}</h2>
                                    </div>
                                    {candidate_data[2].experience.map((i: { position: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; company: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; position_desc: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; date: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) => (
                                        <div className='flex flex-row justify-between my-5'>
                                            <div className='flex flex-col w-7/12'>
                                                <span className='mb-2 font-bold'>{i.position} | {i.company}</span>
                                                <span>{i.position_desc}</span>
                                            </div>
                                            <div className='flex flex-col'>
                                                <span className='mb-2 font-bold'>{i.date}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='flex flex-col w-5/12 ml-5'>
                                <div className='flex flex-row space-x-8 justify-start items-center h-10'>
                                    <img className='w-10 h-10' src={icoEducation} alt={icoEducation} />
                                    <h2 className='text-2xl text-center text-cyan-500'>{dicctionary.education.en}</h2>
                                </div>
                                {candidate_data[2].education.map((i: { major: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; career: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; university: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; date: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) => (
                                    <div className='flex flex-row justify-evenly my-5'>
                                        <span className='mb-2 font-normal'>{i.major}</span>
                                        <span className='mb-2 font-normal'>|  {i.career} </span>
                                        <span className='mb-2 font-normal'>|  {i.university} </span>
                                        <span className='mb-2 font-normal'>|  {i.date}</span>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        <div className='grid grid-row-2 mt-10'>
                            <div className='flex flex-row space-x-8 justify-start items-center h-10'>
                                <img className='w-10 h-10' src={icoSkill} alt={icoSkill} />
                                <h2 className='text-2xl text-center text-cyan-500'>{dicctionary.skills.en}</h2>
                            </div>
                            <div className='flex flex-row justify-evenly my-5'>
                                {candidate_data[2].skills.map((i: { skill: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; specialization: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) => (
                                    <span className='mb-2 text-xl font-semibold'>{i.skill} = {i.specialization}</span>
                                ))}
                            </div>
                        </div>
                        <div className='grid grid-row-2 mt-40'>
                            <div className='flex flex-row space-x-8 justify-center items-center h-10'>
                                <img className='w-40 h-20' src={btnAccept} alt={icoSkill} />
                                <img className='w-40 h-20' src={btnDecline} alt={icoSkill} />
                            </div>
                        </div>
                    </div>
                </div>}

                {/* View 4 */}
                <div className='containerImg' >
                    {/* <div className='imagesInCarouselPricing'></div> */}

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", position: "relative" }}>
                        <img className='containerImg' style={{ height: "95vh" }} src="" alt='' />
                        <div style={{ zIndex: 0, position: "absolute", justifyContent: "center", display: "flex", flexDirection: "column", width: "70%", height: "87%", marginTop: "2%", }}>
                            <div style={{ width: "100%", height: "75%", flexDirection: "row", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                                <div style={{ width: "33%", height: "90%", position: "relative", backgroundColor: "#2c2c5c", border: "0.4vh solid #363663", borderTopLeftRadius: "2vh", borderTopRightRadius: "2vh", alignItems: "center", display: "flex", flexDirection: "column", paddingTop: "10vh", }}>
                                    <img style={{ width: "10vw", position: "absolute", top: "-5vh", }} src={pricing1} alt={pricing1} />
                                    <Text style={{ fontSize: "3vh", color: "white" }}>Career Feed</Text>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                        <Text style={{ fontSize: "2vh", color: "white", marginTop: "1.5vh" }}>$</Text>
                                        <Text style={{ fontSize: "5vh", color: "white", fontWeight: "bold" }}>150</Text>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", position: "relative" }}>
                                        <div style={{ width: "85%", height: "100%", marginLeft: "-16.5%", position: "absolute", zIndex: 1, backgroundColor: "#7067ae", top: 0, borderTopRightRadius: "1vh", borderBottomRightRadius: "1vh" }}></div>
                                        <Text style={{ fontSize: "2vh", color: "black", fontWeight: "bold", width: "100%", textAlign: "center", zIndex: 2 }} >per match</Text>
                                    </div>
                                    <Text className='textPricing4' >Optimal Position</Text>
                                    <Text className='textPricing4' >Spontaneous Applications</Text>
                                    <Text className='textPricing4' >Automated Matching</Text>
                                    <Text className='textPricing4' >Dashboard</Text>
                                </div>


                                <div style={{ width: "33%", height: "100%", backgroundColor: "#c9c9c9", border: "0.0vh solid #c9c9c9", borderTopLeftRadius: "2vh", borderTopRightRadius: "2vh" }}>
                                    <div style={{ width: "100%", height: "100%", paddingTop: "10vh", position: "relative", backgroundColor: "#2c2c5c", border: "0.4vh solid #363663", borderTopLeftRadius: "2vh", borderTopRightRadius: "2vh", marginTop: "1vh%", alignItems: "center", display: "flex", flexDirection: "column" }}>
                                        <img style={{ width: "10vw", position: "absolute", top: "-5vh" }} src={pricing2} alt={pricing2} />
                                        <Text style={{ fontSize: "3vh", color: "white" }}>Career Feed</Text>
                                        <Text style={{ fontSize: "3vh", color: "white", marginTop: "-0.5vh" }}>+ AI Search</Text>
                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                            <Text style={{ fontSize: "2vh", color: "white", marginTop: "1.5vh" }}>$</Text>
                                            <Text style={{ fontSize: "5vh", color: "white", fontWeight: "bold" }}>800</Text>
                                        </div>
                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", position: "relative" }}>
                                            <div style={{ width: "85%", height: "100%", marginLeft: "-16.5%", position: "absolute", zIndex: 1, backgroundColor: "#7067ae", top: 0, borderTopRightRadius: "1vh", borderBottomRightRadius: "1vh" }}></div>
                                            <Text style={{ fontSize: "2vh", color: "black", fontWeight: "bold", width: "100%", textAlign: "center", zIndex: 2 }} >per job</Text>
                                        </div>
                                        <Text className='textPricing4'>Dynamic Position</Text>
                                        <Text className='textPricing4' >100 solicited applications</Text>
                                        <Text className='textPricing4' >Advanced AI Matching</Text>
                                        <Text className='textPricing4' >Dashboard</Text>
                                        <Text className='textPricing4' >Real-time information</Text>
                                    </div>
                                </div>
                                <div style={{ width: "33%", height: "90%", position: "relative", backgroundColor: "#2c2c5c", border: "0.4vh solid #363663", borderTopLeftRadius: "2vh", borderTopRightRadius: "2vh", alignItems: "center", display: "flex", flexDirection: "column", paddingTop: "10vh", }}>

                                    <img style={{ width: "10vw", position: "absolute", top: "-5vh" }} src={pricing3} alt={pricing3} />
                                    <Text style={{ fontSize: "3vh", color: "white" }}>Premium</Text>

                                    <Text style={{ fontSize: "4vh", color: "white", fontWeight: "bold" }}>Contact Sales</Text>


                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", position: "relative" }}>
                                        <div style={{ width: "85%", height: "100%", marginLeft: "-16.5%", position: "absolute", zIndex: 1, backgroundColor: "#7067ae", top: 0, borderTopRightRadius: "1vh", borderBottomRightRadius: "1vh" }}></div>
                                        <Text style={{ fontSize: "2vh", color: "black", fontWeight: "bold", width: "100%", textAlign: "center", zIndex: 2 }} >Enterprise solution</Text>
                                    </div>
                                    <Text className='textPricing4' >Strategic Position:</Text>
                                    <Text className='textPricing4' >Unlimited Applications</Text>
                                    <Text className='textPricing4' >Dedicated Researcher</Text>
                                    <Text className='textPricing4' >Premium Dashboard</Text>
                                    <Text className='textPricing4' >Real-time Information</Text>
                                    <Text className='textPricing4' >Salary Survey</Text>
                                </div>
                            </div>
                            <div onClick={() => window.open('https://gocareerswipe.com/home-fr/', '_blank')} style={{ width: "99%", position: "relative", height: "15%", backgroundColor: "#f39406", marginLeft: "0.5%", borderBottomLeftRadius: "5vh", borderBottomRightRadius: "5vh", justifyContent: "space-evenly", display: "flex", alignItems: "center", }}>
                                <p onClick={()=>window.open('https://calendly.com/irgt2023/15min')} style={{color: "blue", cursor: "pointer", fontSize:'16px',textDecorationLine:'underline'}} >Schedule demo</p>
                                <div>
                                <Text style={{ fontSize: "4vh", color: "blue", cursor: "pointer", textDecoration: "underline",textAlign:'center' }}>gocareerswipe.com</Text>
                                <p style={{textAlign:'center'}} >sales@gocareerswipe.com</p>
                                </div>
                               <div>
                               <img onClick={() => window.open('https://www.youtube.com/watch?v=vWZ_ME5YrwQ&ab_channel=CareerSwipe', '_blank')} style={{ height: "4vh", marginLeft: 10, position: "absolute", top: "40%", right: "10%" }} src={iconYoutube} alt={iconYoutube} />
                                <img onClick={() => window.open('https://www.linkedin.com/company/services-career-swipe/?viewAsMember=true', '_blank')} style={{ height: "4vh", marginLeft: 10, position: "absolute", top: "40%", right: "15%" }} src={iconLinkeding} alt={iconYoutube} />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
            <Button
                className='buttonNextPag'
                onClick={() => handleNext()}
            >
                <Text style={{ fontSize: "5vh", color: 'white' }}>
                    <img className='iconRowStyle' src={rowRight} alt={rowRight} />
                </Text>
            </Button>

            <div style={{ width: "100vw", height: "3vh", position: "fixed", bottom: "3vh", display: "flex", alignItems: "center", justifyContent: "center", zIndex: 2, }}>
                <div className={currentPage === 1 ? 'dotCarSelected' : 'dotCar'}></div>
                <div className={currentPage === 2 ? 'dotCarSelected' : 'dotCar'}></div>
                <div className={currentPage === 3 ? 'dotCarSelected' : 'dotCar'}></div>
                <div className={currentPage === 4 ? 'dotCarSelected' : 'dotCar'}></div>
                <div className={currentPage === 5 ? 'dotCarSelected' : 'dotCar'}></div>
                <div className={currentPage === 6 ? 'dotCarSelected' : 'dotCar'}></div>
            </div>
        </div>
    )
}

export default CandidateView
import { useRef, useState } from 'react'
import { Button, Carousel, Typography, } from 'antd';
import "../../../styles/styles.css"
import icoWork from '../../../images/ico-work.png'
import icoEducation from '../../../images/ico-education.png'
import icoSkill from '../../../images/ico_skills.png'
import rowLeft from '../../../images/btn-left.png'
import rowRight from '../../../images/btn-right.png'
import btnAccept from '../../../images/btn-Accepter.png'
import btnDecline from '../../../images/btn-Decliner.png'
import pricing1 from '../../../images/icon-pricing1.png'
import pricing2 from '../../../images/icon-pricing2.png'
import pricing3 from '../../../images/icon-pricing3.png'
import iconYoutube from '../../../images/yt_icon_rgb.png'
import iconLinkeding from '../../../images/LI-In-Bug.png'
import backgroundLogin from '../../../images/backgroundLogin.png'
import { dicctionary } from '../../../constants/dictionnary';
import { candidate_data } from '../../../constants/data_fr';
import View from "../../../images/view.png"



const { Text } = Typography;
// Component

const CandidateView = () => {

    const carouselRef = useRef<any>(null);

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    const handlePrev = () => {
        if (carouselRef.current) {
            carouselRef.current.prev();
        }
    };

    const downloadPDF = () => {
        const rutaPDF = 'https://firebasestorage.googleapis.com/v0/b/jobmatch-e66b9.appspot.com/o/PDF%2FLesServicesCareerSwipe2023.pdf?alt=media&token=049a5ff4-df0a-4d9f-a8b9-4260a1017803&_gl=1*3nfp6f*_ga*MjAyMDg3OTc5Mi4xNjM1NzEwMzQw*_ga_CW55HF8NVT*MTY5OTQ2MTI0MC4xNi4xLjE.*';

        // Realiza una solicitud para obtener el archivo PDF
        fetch(rutaPDF)
            .then((response) => response.blob())
            .then((blob) => {
                // Crea una URL a partir del Blob
                const url = URL.createObjectURL(blob);

                // Crea un enlace oculto y dispara un clic para descargar el archivo
                const enlace = document.createElement('a');
                enlace.href = url;
                enlace.target = '_blank';
                enlace.download = 'Les Services Career Swipe 2023.pdf';
                enlace.style.display = 'none';
                document.body.appendChild(enlace);
                enlace.click();
                document.body.removeChild(enlace);
            });
    };
    const [currentPage, setCurrentPage] = useState(1);
    const handleAfterChange = (current: any) => {
        setCurrentPage(current + 1);
    };

    return (
        <div className='containerRow' style={{ display: 'flex', alignItems: 'center' }}>


            <Button
                className='buttonPrevPag'
                onClick={() => handlePrev()}
            >
                <Text style={{ fontSize: "5vh", color: 'white' }}>
                    <img className='iconRowStyle' src={rowLeft} alt={rowLeft} />
                </Text>
            </Button>
            <Carousel dots={false} className='containerViewsInCarousel' style={{ overflow: "hidden", minWidth: 1200 }} ref={carouselRef} afterChange={handleAfterChange}>
                {/* View 1 */}
                <div style={{ display: 'flex', alignContent: 'center' }}>
                    <p style={{ textAlign: 'center', fontSize: '42px', fontWeight: 'bold', alignSelf: 'center', marginTop: 320 }}>Bienvenue sur Career Swipe. Voici la</p>
                </div>
                <div className=' mt-40 ml-48'>
                    <img className='self-center' src={View} alt={View} />
                </div>

                {/* View 2 */}
                <div className='container mx-auto p-5'>
                    <div className='flex flex-col'>
                        <div className='flex flex-row'>
                            <div className="flex-none w-7/12 h-auto">
                                <div className='flex flex-col'>
                                    <div className='flex flex-row space-x-8 justify-start items-center h-10'>
                                        <img className='w-10 h-10' src={icoWork} alt={icoWork} />
                                        <h2 className='text-2xl text-center text-cyan-500'>{dicctionary.experience.fr}</h2>
                                    </div>
                                    {candidate_data.experience.map(i => (
                                        <div className='flex flex-row justify-between my-5'>
                                            <div className='flex flex-col w-10/12'>
                                                <span className='mb-2 font-bold'>{i.position} | {i.company}</span>
                                                <span>{i.position_desc}</span>
                                            </div>
                                            <div className='flex flex-col'>
                                                <span className='mb-2 font-bold'>{i.date}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='flex flex-col w-5/12 ml-5'>
                                <div className='flex flex-row space-x-8 justify-start items-center h-10'>
                                    <img className='w-10 h-10' src={icoEducation} alt={icoEducation} />
                                    <h2 className='text-2xl text-center text-cyan-500'>{dicctionary.education.fr}</h2>
                                </div>
                                {candidate_data.education.map(i => (
                                    <div className='flex flex-row justify-evenly my-5'>
                                        <span className='mb-2 font-normal'>{i.major}</span>
                                        <span className='mb-2 font-normal'>|  {i.career} </span>
                                        <span className='mb-2 font-normal'>|  {i.university} </span>
                                        <span className='mb-2 font-normal'>|  {i.date}</span>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        <div className='grid grid-row-2'>
                            <div className='flex flex-row space-x-8 justify-start items-center h-10'>
                                <img className='w-10 h-10' src={icoSkill} alt={icoSkill} />
                                <h2 className='text-2xl text-center text-cyan-500'>{dicctionary.skills.fr}</h2>
                            </div>
                                <div className='flex flex-row justify-evenly my-15'>
                            {candidate_data.skills.map(i => (
                                    <span className='mb-2 text-xl font-semibold'>{i.skill} = {i.specialization}</span>
                                    ))}
                                </div>
                        </div>
                        <div className='grid grid-row-2 mt-40'>
                            <div className='flex flex-row space-x-8 justify-center items-center h-10'>
                                <img className='w-40 h-20' src={btnAccept} alt={icoSkill} />
                                <img className='w-40 h-20' src={btnDecline} alt={icoSkill} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                </div>
              
                {/* View 4 */}
                <div className='containerImg' >
                    {/* <div className='imagesInCarouselPricing'></div> */}

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", position: "relative" }}>
                        <img className='containerImg' style={{ height: "95vh" }} src={backgroundLogin} alt={backgroundLogin} />
                        <div style={{ zIndex: 0, position: "absolute", justifyContent: "center", display: "flex", flexDirection: "column", width: "70%", height: "87%", marginTop: "2%", }}>
                            <div style={{ width: "100%", height: "75%", flexDirection: "row", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                                <div style={{ width: "33%", height: "90%", position: "relative", backgroundColor: "#2c2c5c", border: "0.4vh solid #363663", borderTopLeftRadius: "2vh", borderTopRightRadius: "2vh", alignItems: "center", display: "flex", flexDirection: "column", paddingTop: "10vh", }}>
                                    <img style={{ width: "10vw", position: "absolute", top: "-5vh", }} src={pricing1} alt={pricing1} />
                                    <Text style={{ fontSize: "3vh", color: "white" }}>Flux de Carrière</Text>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                        <Text style={{ fontSize: "2vh", color: "white", marginTop: "1.5vh" }}>$</Text>
                                        <Text style={{ fontSize: "5vh", color: "white", fontWeight: "bold" }}>150</Text>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", position: "relative" }}>
                                        <div style={{ width: "85%", height: "100%", marginLeft: "-16.5%", position: "absolute", zIndex: 1, backgroundColor: "#7067ae", top: 0, borderTopRightRadius: "1vh", borderBottomRightRadius: "1vh" }}></div>
                                        <Text style={{ fontSize: "2vh", color: "black", fontWeight: "bold", width: "100%", textAlign: "center", zIndex: 2 }} >par poste</Text>
                                    </div>
                                    <Text className='textPricing4' >Position optimale</Text>
                                    <Text className='textPricing4' >Candidatures spontanées</Text>
                                    <Text className='textPricing4' >Matching Automatisé</Text>
                                    <Text className='textPricing4' >Tableau de bord</Text>
                                </div>


                                <div style={{ width: "33%", height: "100%", backgroundColor: "#c9c9c9", border: "0.0vh solid #c9c9c9", borderTopLeftRadius: "2vh", borderTopRightRadius: "2vh" }}>
                                    <div style={{ width: "100%", height: "100%", paddingTop: "10vh", position: "relative", backgroundColor: "#2c2c5c", border: "0.4vh solid #363663", borderTopLeftRadius: "2vh", borderTopRightRadius: "2vh", marginTop: "1vh%", alignItems: "center", display: "flex", flexDirection: "column" }}>
                                        <img style={{ width: "10vw", position: "absolute", top: "-5vh" }} src={pricing2} alt={pricing2} />
                                        <Text style={{ fontSize: "3vh", color: "white" }}>Flux de Carrière</Text>
                                        <Text style={{ fontSize: "3vh", color: "white", marginTop: "-0.5vh" }}>+Recherche IA</Text>
                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                            <Text style={{ fontSize: "2vh", color: "white", marginTop: "1.5vh" }}>$</Text>
                                            <Text style={{ fontSize: "5vh", color: "white", fontWeight: "bold" }}>800</Text>
                                        </div>
                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", position: "relative" }}>
                                            <div style={{ width: "85%", height: "100%", marginLeft: "-16.5%", position: "absolute", zIndex: 1, backgroundColor: "#7067ae", top: 0, borderTopRightRadius: "1vh", borderBottomRightRadius: "1vh" }}></div>
                                            <Text style={{ fontSize: "2vh", color: "black", fontWeight: "bold", width: "100%", textAlign: "center", zIndex: 2 }} >par poste</Text>
                                        </div>
                                        <Text className='textPricing4'>Position dynamique</Text>
                                        <Text className='textPricing4' >100 candidatures sollicitées</Text>
                                        <Text className='textPricing4' >Matching IA Avancée</Text>
                                        <Text className='textPricing4' >Tableau de bord</Text>
                                        <Text className='textPricing4' >Information temps réel</Text>
                                    </div>
                                </div>



                                <div style={{ width: "33%", height: "90%", position: "relative", backgroundColor: "#2c2c5c", border: "0.4vh solid #363663", borderTopLeftRadius: "2vh", borderTopRightRadius: "2vh", alignItems: "center", display: "flex", flexDirection: "column", paddingTop: "10vh", }}>

                                    <img style={{ width: "10vw", position: "absolute", top: "-5vh" }} src={pricing3} alt={pricing3} />
                                    <Text style={{ fontSize: "3vh", color: "white" }}>Premium</Text>

                                    <Text style={{ fontSize: "4vh", color: "white", fontWeight: "bold" }}>Contacter Ventes</Text>


                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", position: "relative" }}>
                                        <div style={{ width: "85%", height: "100%", marginLeft: "-16.5%", position: "absolute", zIndex: 1, backgroundColor: "#7067ae", top: 0, borderTopRightRadius: "1vh", borderBottomRightRadius: "1vh" }}></div>
                                        <Text style={{ fontSize: "2vh", color: "black", fontWeight: "bold", width: "100%", textAlign: "center", zIndex: 2 }} >5+ postes</Text>
                                    </div>
                                    <Text className='textPricing4' >Position Stratégique</Text>
                                    <Text className='textPricing4' >Candidatures illimitées</Text>
                                    <Text className='textPricing4' >Recherchiste dédié</Text>
                                    <Text className='textPricing4' >Tableau de bord Premium</Text>
                                    <Text className='textPricing4' >Information temps réel</Text>
                                    <Text className='textPricing4' >Sondage Salarial</Text>
                                </div>




                            </div>
                            <div onClick={() => window.open('https://gocareerswipe.com/home-fr/', '_blank')} style={{ width: "99%", position: "relative", height: "15%", backgroundColor: "#f39406", marginLeft: "0.5%", borderBottomLeftRadius: "5vh", borderBottomRightRadius: "5vh", justifyContent: "center", display: "flex", alignItems: "center", }}>
                                <Text style={{ fontSize: "4vh", color: "blue", cursor: "pointer", textDecoration: "underline", }}>gocareerswipe.com</Text>
                                <img onClick={() => window.open('https://www.youtube.com/watch?v=vWZ_ME5YrwQ&ab_channel=CareerSwipe', '_blank')} style={{ height: "4vh", marginLeft: 10, position: "absolute", top: "40%", right: "10%" }} src={iconYoutube} alt={iconYoutube} />
                                <img onClick={() => window.open('https://www.linkedin.com/company/services-career-swipe/?viewAsMember=true', '_blank')} style={{ height: "4vh", marginLeft: 10, position: "absolute", top: "40%", right: "15%" }} src={iconLinkeding} alt={iconYoutube} />
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
            <Button
                className='buttonNextPag'
                onClick={() => handleNext()}
            >
                <Text style={{ fontSize: "5vh", color: 'white' }}>
                    <img className='iconRowStyle' src={rowRight} alt={rowRight} />
                </Text>
            </Button>

            <div style={{ width: "100vw", height: "3vh", position: "fixed", bottom: "3vh", display: "flex", alignItems: "center", justifyContent: "center", zIndex: 2, }}>
                <div className={currentPage === 1 ? 'dotCarSelected' : 'dotCar'}></div>
                <div className={currentPage === 2 ? 'dotCarSelected' : 'dotCar'}></div>
                <div className={currentPage === 3 ? 'dotCarSelected' : 'dotCar'}></div>
                <div className={currentPage === 4 ? 'dotCarSelected' : 'dotCar'}></div>
            </div>
        </div>
    )
}

export default CandidateView
import { Content } from "antd/lib/layout/layout";
import CandidateView from '../../components/proyect1/candidate/CandidateView';
import bgImage from '../../images/bg-img.png'



const CandidateScreen = () => {
  return (
    <Content style={{ height: "100vh", width: "100vw", minWidth:1200}} >
      <div>
        <img style={{width:"100vw", height:"100vh", position:'absolute', backgroundColor:"red", zIndex:0, minWidth:1350}} src={bgImage} alt={bgImage} />
      </div>
      <div style={{height:'100%', width: "100%", minWidth:1200}}>
          <CandidateView />
      </div>
    </Content>
  )
}

export default CandidateScreen
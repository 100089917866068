export const candidate_data = {

    experience: [
        {
            position: 'Superviseur', 
            company: 'Plastigroulx',
            date: '2022 - 2023',
            position_desc: "Le superviseur est responsable de diriger et de motiver une équipe d'employés pour atteindre les objectifs du département et de l'entreprise. Il est chargé de planifier, d'organiser, de diriger et de contrôler le travail de l'équipe, ainsi que d'évaluer le rendement individuel et collectif." // French translation of position description
        },
        {
            position: 'Superviseur',
            company: 'Plastigroulx',
            date: '2022 - 2023',
            position_desc: "Le superviseur est responsable de diriger et de motiver une équipe d'employés pour atteindre les objectifs du département et de l'entreprise. Il est chargé de planifier, d'organiser, de diriger et de contrôler le travail de l'équipe, ainsi que d'évaluer le rendement individuel et collectif."
        },
        {
            position: 'Superviseur',
            company: 'Plastigroulx',
            date: '2022 - 2023',
            position_desc: "Le superviseur est responsable de diriger et de motiver une équipe d'employés pour atteindre les objectifs du département et de l'entreprise. Il est chargé de planifier, d'organiser, de diriger et de contrôler le travail de l'équipe, ainsi que d'évaluer le rendement individuel et collectif."
        },
    ],
    education: [
        {
            major: 'Université',
            university: 'Inacap',
            date: '2022',
            career: "Développeur Web" 
        },
    ],
    skills: [
        {
            skill: 'React',
            specialization: '5/5'
        },
        {
            skill: 'Vue',
            specialization: '2/5'
        },
        {
            skill: 'HTML',
            specialization: '4/5'
        },
        {
            skill: 'CSS',
            specialization: '3/5'
        },
        {
            skill: 'Node.js',
            specialization: '3/5'
        },
    ]

}
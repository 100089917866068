//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------- 
import firebase from "firebase/app";
import "firebase/firestore"
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCzQJcOKaO8T4l1hnqObjcVfD_h--5hggo",
  authDomain: "jobmatch-e66b9.firebaseapp.com",
  databaseURL: "https://jobmatch-e66b9-default-rtdb.firebaseio.com/",
  projectId: "jobmatch-e66b9",
  storageBucket: "jobmatch-e66b9.appspot.com",
  messagingSenderId: "340649571219",
  appId: "1:340649571219:web:89670151698a2fcb5eee66",
  measurementId: "G-M3YRGFTED0"
};

firebase.initializeApp(firebaseConfig);

//Export
export default firebase;
import React from 'react'


const miCodigoHTML = `
  <div>
  <html>
  <head>
      <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>

  <body class="c32 doc-content">

     

      <p class="c21"><span class="c0">Career Swipe</span></p>
      <p class="c21 c3"><span class="c0"></span></p>
      <p class="c21"><span class="c0">Chez Career Swipe, la transparence et l&rsquo;int&eacute;grit&eacute;&nbsp;sont des valeurs importantes. </span></p>
      <p class="c21 c3"><span class="c0"></span></p>
      <p class="c21"><span class="c0">Ci-bas vous trouverez la POLITIQUE DE CONFIDENTIALIT&Eacute; et notre engagement envers nos clients.</span></p>
      <p class="c21 c3"><span class="c0"></span></p>
      <p class="c21 c3"><span class="c0"></span></p>
      <p class="c14 c3"><span class="c0"></span></p>
      <p class="c14"><span class="c25 c9">Derni&egrave;re mise &agrave; jour le 05 juin 2023</span></p>
      <p class="c14 c3"><span class="c0"></span></p>
      <p class="c14 c3"><span class="c0"></span></p>
      <p class="c14">
          <span class="c1">
              Cet avis de confidentialit&eacute; pour IRGT Advisory Service Inc. (&quot;Entreprise,&quot; &quot;nous,&quot; &quot;nous,&quot; ou &quot;notre&quot;), d&eacute;crit comment et pourquoi nous pourrions collecter, stocker,
              utiliser et/ou partager (&quot;processus&quot;) vos informations lorsque vous utilisez nos services (&quot;Prestations de service&quot;), par exemple lorsque vous&nbsp;:
          </span>
      </p>
      <ul class="c5 lst-kix_ty2lgjf81efi-0 start">
          <li class="c4">
              <span class="c6">Visitez notre site Web &agrave;</span>
              <span class="c6"><a class="c13" href="https://www.google.com/url?q=https://gocareerswipe.com/&amp;sa=D&amp;source=editors&amp;ust=1700238783092923&amp;usg=AOvVaw3EhBLUYJ1ZSL2Gm5vwHVvN">&nbsp;</a></span>
              <span class="c16 c9">
                  <a class="c13" href="https://www.google.com/url?q=https://gocareerswipe.com/&amp;sa=D&amp;source=editors&amp;ust=1700238783093306&amp;usg=AOvVaw3BcGzltnSoQ9J4qitcDfa0">https://gocareerswipe.com</a>
              </span>
              <span class="c1">, ou l&#39;un de nos sites Web qui renvoie &agrave; cet avis de confidentialit&eacute;</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_o0gfrzkpdbin-0 start">
          <li class="c4"><span class="c1">T&eacute;l&eacute;chargez et utilisez notre application mobile (Career Swipe), ou toute autre application de la n&ocirc;tre qui renvoie &agrave; cet avis de confidentialit&eacute;</span></li>
      </ul>
      <ul class="c5 lst-kix_5t9q6xxifhy0-0 start">
          <li class="c4"><span class="c1">S&#39;engager avec nous d&#39;autres mani&egrave;res connexes, y compris les ventes, le marketing ou les &eacute;v&eacute;nements</span></li>
      </ul>
      <p class="c2">
          <span class="c9 c25">
              Questions ou pr&eacute;occupations?La lecture de cet avis de confidentialit&eacute; vous aidera &agrave; comprendre vos droits et vos choix en mati&egrave;re de confidentialit&eacute;. Si vous n&#39;&ecirc;tes pas
              d&#39;accord avec nos politiques et pratiques, veuillez ne pas utiliser nos Services. Si vous avez encore des questions ou des pr&eacute;occupations, veuillez nous contacter &agrave; careerswipe@irgt.ca.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">R&Eacute;SUM&Eacute; DES POINTS CL&Eacute;S</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c7">
              Ce r&eacute;sum&eacute; fournit les points cl&eacute;s de notre avis de confidentialit&eacute;, mais vous pouvez trouver plus de d&eacute;tails sur l&#39;un de ces sujets en cliquant sur le lien suivant chaque point
              cl&eacute; ou en utilisant notre table des mati&egrave;res ci-dessous pour trouver la section que vous recherchez.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c0">
              Quelles informations personnelles traitons-nous ? Lorsque vous visitez, utilisez ou naviguez sur nos Services, nous pouvons traiter des informations personnelles en fonction de la mani&egrave;re dont vous interagissez avec
              IRGT Advisory Service Inc. et les Services, des choix que vous faites et des produits et fonctionnalit&eacute;s que vous utilisez. En savoir plus sur les informations personnelles que vous nous divulguez.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Traitons-nous des informations personnelles sensibles ? Nous ne traitons pas les informations personnelles sensibles.</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Recevons-nous des informations de tiers ? Nous ne recevons aucune information de tiers.</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c0">
              Comment traitons-nous vos informations ? Nous traitons vos informations pour fournir, am&eacute;liorer et administrer nos Services, communiquer avec vous, pour la s&eacute;curit&eacute; et la pr&eacute;vention de la fraude,
              et pour nous conformer &agrave; la loi. Nous pouvons &eacute;galement traiter vos informations &agrave; d&#39;autres fins avec votre consentement. Nous traitons vos informations uniquement lorsque nous avons une raison
              l&eacute;gale valable de le faire. En savoir plus sur la fa&ccedil;on dont nous traitons vos informations.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c0">
              Dans quelles situations et avec quelles parties partageons-nous des informations personnelles ? Nous pouvons partager des informations dans des situations sp&eacute;cifiques et avec des tiers sp&eacute;cifiques. En savoir
              plus sur quand et avec qui nous partageons vos informations personnelles.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c0">
              Comment prot&eacute;geons-nous vos informations&nbsp;? Nous avons mis en place des processus et proc&eacute;dures organisationnels et techniques pour prot&eacute;ger vos informations personnelles. Cependant, aucune
              transmission &eacute;lectronique sur Internet ou technologie de stockage d&#39;informations ne peut &ecirc;tre garantie &agrave; 100% s&eacute;curis&eacute;e, nous ne pouvons donc pas promettre ou garantir que les pirates,
              les cybercriminels ou d&#39;autres tiers non autoris&eacute;s ne seront pas en mesure de d&eacute;jouer notre s&eacute;curit&eacute; et de collecter, d&#39;acc&eacute;der de mani&egrave;re inappropri&eacute;e , voler ou
              modifier vos informations. En savoir plus sur la fa&ccedil;on dont nous prot&eacute;geons vos informations.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c0">
              Quels sont vos droits ? Selon l&#39;endroit o&ugrave; vous vous trouvez g&eacute;ographiquement, la loi sur la confidentialit&eacute; applicable peut signifier que vous avez certains droits concernant vos informations
              personnelles. En savoir plus sur vos droits &agrave; la vie priv&eacute;e.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c9">Comment exercez-vous vos droits ? Le moyen le plus simple d&#39;exercer vos droits est de visiter</span>
          <span class="c9"><a class="c13" href="https://www.google.com/url?q=https://gocareerswipe.com/&amp;sa=D&amp;source=editors&amp;ust=1700238783095787&amp;usg=AOvVaw2d2uh9J9f9G6o-COiyU6Kj">&nbsp;</a></span>
          <span class="c16 c9"><a class="c13" href="https://www.google.com/url?q=https://gocareerswipe.com/&amp;sa=D&amp;source=editors&amp;ust=1700238783095963&amp;usg=AOvVaw3AOTvbCzWpZQv7e4MGRIaE">https://gocareerswipe.com/</a></span>
          <span class="c0">, ou en nous contactant. Nous examinerons et agirons sur toute demande conform&eacute;ment aux lois applicables en mati&egrave;re de protection des donn&eacute;es.</span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c0">Vous voulez en savoir plus sur ce que IRGT Advisory Service Inc. fait avec les informations que nous recueillons ? Lisez l&#39;avis de confidentialit&eacute; dans son int&eacute;gralit&eacute;.</span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">TABLE DES MATI&Egrave;RES</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c1">1. QUELLES INFORMATIONS COLLECTONS-NOUS ?</span></p>
      <p class="c2"><span class="c1">2. COMMENT TRAITONS-NOUS VOS INFORMATIONS ?</span></p>
      <p class="c2"><span class="c1">3. SUR QUELLES BASES JURIDIQUES NOUS APPUYONS-NOUS POUR TRAITER VOS INFORMATIONS PERSONNELLES ?</span></p>
      <p class="c2"><span class="c6">4. QUAND ET AVEC QUI </span><span class="c6">PARTAGEONS</span><span class="c1">-NOUS VOS INFORMATIONS PERSONNELLES ?</span></p>
      <p class="c2"><span class="c1">5. COMMENT G&Eacute;RONS-NOUS VOS CONNEXIONS SOCIALES&nbsp;?</span></p>
      <p class="c2"><span class="c1">6. COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS ?</span></p>
      <p class="c2"><span class="c1">7. COMMENT GARDONS-NOUS LA S&Eacute;CURIT&Eacute; DE VOS INFORMATIONS&nbsp;?</span></p>
      <p class="c2"><span class="c1">8. COLLECTONS-NOUS DES INFORMATIONS AUPR&Egrave;S DE MINEURS&nbsp;?</span></p>
      <p class="c2"><span class="c1">9. QUELS SONT VOS DROITS EN MATI&Egrave;RE DE CONFIDENTIALIT&Eacute;&nbsp;?</span></p>
      <p class="c2"><span class="c1">10. COMMANDES POUR LES FONCTIONS NE PAS SUIVRE</span></p>
      <p class="c2"><span class="c1">11. LES R&Eacute;SIDENTS DE CALIFORNIE DISPOSENT-ILS DE DROITS SP&Eacute;CIFIQUES &Agrave; LA VIE PRIV&Eacute;E&nbsp;?</span></p>
      <p class="c2"><span class="c0">12. LES R&Eacute;SIDENTS DE VIRGINIE ONT-ILS DES DROITS SP&Eacute;CIFIQUES &Agrave; LA VIE PRIV&Eacute;E&nbsp;?</span></p>
      <p class="c2"><span class="c1">13. FAISONS-NOUS DES MISES &Agrave; JOUR &Agrave; CET AVIS&nbsp;?</span></p>
      <p class="c2"><span class="c1">14. COMMENT POUVEZ-VOUS NOUS CONTACTER AU SUJET DE CET AVIS&nbsp;?</span></p>
      <p class="c2"><span class="c1">15. COMMENT POUVEZ-VOUS EXAMINER, METTRE &Agrave; JOUR OU SUPPRIMER LES DONN&Eacute;ES QUE NOUS COLLECTONS &Agrave; VOTRE SUJET&nbsp;?</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">1. QUELLES INFORMATIONS COLLECTONS-NOUS ?</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Informations personnelles que vous nous divulguez</span></p>
      <p class="c14 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c19 c6">Bref:Nous collectons les informations personnelles que vous nous fournissez.</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              Nous collectons les informations personnelles que vous nous fournissez volontairement lorsque vous vous inscrivez sur les Services, exprimez un int&eacute;r&ecirc;t &agrave; obtenir des informations sur nous ou nos produits
              et Services, lorsque vous participez &agrave; des activit&eacute;s sur les Services, ou autrement lorsque vous nous contactez.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              Informations personnelles fournies par vous. Les informations personnelles que nous collectons d&eacute;pendent du contexte de vos interactions avec nous et les Services, des choix que vous faites et des produits et
              fonctionnalit&eacute;s que vous utilisez. Les informations personnelles que nous collectons peuvent inclure les &eacute;l&eacute;ments suivants&nbsp;:
          </span>
      </p>
      <ul class="c5 lst-kix_c4wevbb1j2nl-0 start">
          <li class="c4"><span class="c1">des noms</span></li>
      </ul>
      <ul class="c5 lst-kix_qb137132qce5-0 start">
          <li class="c4"><span class="c1">adresses mail</span></li>
      </ul>
      <ul class="c5 lst-kix_r8xfafsekngq-0 start">
          <li class="c4"><span class="c1">titres de poste</span></li>
      </ul>
      <ul class="c5 lst-kix_o21ld91xedh7-0 start">
          <li class="c4"><span class="c1">&eacute;ducation</span></li>
      </ul>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Information sensible. Nous ne traitons pas d&#39;informations sensibles.</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              Donn&eacute;es de connexion aux m&eacute;dias sociaux.Nous pouvons vous offrir la possibilit&eacute; de vous inscrire aupr&egrave;s de nous en utilisant les d&eacute;tails de votre compte de r&eacute;seau social existant,
              comme votre compte Facebook, Twitter ou un autre compte de r&eacute;seau social. Si vous choisissez de vous inscrire de cette mani&egrave;re, nous collecterons les informations d&eacute;crites dans la section
              intitul&eacute;e &quot;COMMENT G&Eacute;RONS-NOUS VOS CONNEXIONS SOCIALES&nbsp;?&quot; dessous.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c0">Application Data. Si vous utilisez nos applications, nous pouvons &eacute;galement collecter les informations suivantes si vous choisissez de nous fournir un acc&egrave;s ou une autorisation&nbsp;:</span>
      </p>
      <ul class="c5 lst-kix_ev13pfyq07ka-0 start">
          <li class="c4">
              <span class="c9 c33">Notifications push.</span>
              <span class="c0">
                  &nbsp;Nous pouvons vous demander de vous envoyer des notifications push concernant votre compte ou certaines fonctionnalit&eacute;s de la ou des applications. Si vous souhaitez refuser de recevoir ces types de
                  communications, vous pouvez les d&eacute;sactiver dans les param&egrave;tres de votre appareil.
              </span>
          </li>
      </ul>
      <p class="c2">
          <span class="c9">
              Ces informations sont principalement n&eacute;cessaires pour maintenir la s&eacute;curit&eacute; et le fonctionnement de nos applications, pour le d&eacute;pannage et &agrave; des fins d&#39;analyse et de rapport
          </span>
          <span class="c9">internes</span><span class="c0">.</span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              Toutes les informations personnelles que vous nous fournissez doivent &ecirc;tre v&eacute;ridiques, compl&egrave;tes et exactes, et vous devez nous informer de tout changement apport&eacute; &agrave; ces informations
              personnelles.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">2. COMMENT TRAITONS-NOUS VOS INFORMATIONS ?</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c19 c6">
              Bref:Nous traitons vos informations pour fournir, am&eacute;liorer et administrer nos Services, communiquer avec vous, pour la s&eacute;curit&eacute; et la pr&eacute;vention de la fraude, et pour nous conformer &agrave; la
              loi. Nous pouvons &eacute;galement traiter vos informations &agrave; d&#39;autres fins avec votre consentement.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c1">Nous traitons vos informations personnelles pour diverses raisons, en fonction de la mani&egrave;re dont vous interagissez avec nos Services, notamment&nbsp;:</span></p>
      <ul class="c5 lst-kix_x9g8fqomz0c1-0 start">
          <li class="c4">
              <span class="c1">
                  Pour faciliter la cr&eacute;ation et l&#39;authentification de compte et g&eacute;rer autrement les comptes d&#39;utilisateurs.Nous pouvons traiter vos informations afin que vous puissiez cr&eacute;er et vous connecter
                  &agrave; votre compte, ainsi que maintenir votre compte en &eacute;tat de fonctionnement.
              </span>
          </li>
      </ul>
      <ul class="c5 lst-kix_sh1vtnlsp25y-0 start">
          <li class="c4"><span class="c1">Fournir et faciliter la prestation de services &agrave; l&#39;utilisateur.Nous pouvons traiter vos informations pour vous fournir le service demand&eacute;.</span></li>
      </ul>
      <ul class="c5 lst-kix_xptoizuhir1j-0 start">
          <li class="c4">
              <span class="c1">
                  Pour vous envoyer des informations administratives. Nous pouvons traiter vos informations pour vous envoyer des d&eacute;tails sur nos produits et services, les modifications de nos conditions et politiques, et
                  d&#39;autres informations similaires.
              </span>
          </li>
      </ul>
      <ul class="c5 lst-kix_c3d9dj58pym4-0 start">
          <li class="c4">
              <span class="c1">
                  Pour activer les communications d&#39;utilisateur &agrave; utilisateur.Nous pouvons traiter vos informations si vous choisissez d&#39;utiliser l&#39;une de nos offres permettant la communication avec un autre
                  utilisateur.
              </span>
          </li>
      </ul>
      <ul class="c5 lst-kix_ftltxk1jcnf3-0 start">
          <li class="c4">
              <span class="c1">Pour demander des commentaires.Nous pouvons traiter vos informations si n&eacute;cessaire pour demander des commentaires et vous contacter au sujet de votre utilisation de nos Services.</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_iip644l1y0g9-0 start">
          <li class="c4">
              <span class="c0">
                  Pour &eacute;valuer et am&eacute;liorer nos Services, nos produits, notre marketing et votre exp&eacute;rience. Nous pouvons traiter vos informations lorsque nous pensons qu&#39;il est n&eacute;cessaire d&#39;identifier
                  les tendances d&#39;utilisation, de d&eacute;terminer l&#39;efficacit&eacute; de nos campagnes promotionnelles et d&#39;&eacute;valuer et d&#39;am&eacute;liorer nos Services, nos produits, notre marketing et votre
                  exp&eacute;rience.
              </span>
          </li>
      </ul>
      <ul class="c5 lst-kix_ilaezgdkinxd-0 start">
          <li class="c4">
              <span class="c0">
                  Pour respecter nos obligations l&eacute;gales. Nous pouvons traiter vos informations pour nous conformer &agrave; nos obligations l&eacute;gales, r&eacute;pondre aux demandes l&eacute;gales et exercer, &eacute;tablir ou
                  d&eacute;fendre nos droits l&eacute;gaux.
              </span>
          </li>
      </ul>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">3. SUR QUELLES BASES JURIDIQUES NOUS APPUYONS-NOUS POUR TRAITER VOS INFORMATIONS ?</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c7">
              Bref:Nous ne traitons vos informations personnelles que lorsque nous pensons que cela est n&eacute;cessaire et que nous avons une raison l&eacute;gale valable (c&#39;est-&agrave;-dire une base l&eacute;gale) pour le faire en
              vertu de la loi applicable, comme avec votre consentement, pour nous conformer aux lois, pour vous fournir des services pour entrer dans ou remplir nos obligations contractuelles, prot&eacute;ger vos droits ou satisfaire nos
              int&eacute;r&ecirc;ts commerciaux l&eacute;gitimes.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c9 c17">Si vous r&eacute;sidez au Canada, cette section s&#39;applique &agrave; vous.</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c0">
              Nous pouvons traiter vos informations si vous nous avez donn&eacute; une autorisation sp&eacute;cifique (c&#39;est-&agrave;-dire un consentement expr&egrave;s) pour utiliser vos informations personnelles &agrave; des fins
              sp&eacute;cifiques, ou dans des situations o&ugrave; votre autorisation peut &ecirc;tre d&eacute;duite (c&#39;est-&agrave;-dire un consentement implicite). Vous pouvez retirer votre consentement &agrave; tout moment.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c0">
              Dans certains cas exceptionnels, nous pouvons &ecirc;tre l&eacute;galement autoris&eacute;s, en vertu de la loi applicable, &agrave; traiter vos informations sans votre consentement, y compris, par exemple&nbsp;:
          </span>
      </p>
      <ul class="c5 lst-kix_r2f0fg8s6x5a-0 start">
          <li class="c4"><span class="c0">Si la collecte est clairement dans l&#39;int&eacute;r&ecirc;t d&#39;un individu et que le consentement ne peut &ecirc;tre obtenu en temps opportun</span></li>
      </ul>
      <ul class="c5 lst-kix_id2l688j0ymh-0 start">
          <li class="c4"><span class="c0">Pour les enqu&ecirc;tes et la d&eacute;tection et la pr&eacute;vention des fraudes</span></li>
      </ul>
      <ul class="c5 lst-kix_9lyfqstnamht-0 start">
          <li class="c4"><span class="c0">Pour les transactions commerciales sous certaines conditions</span></li>
      </ul>
      <ul class="c5 lst-kix_vxsj3y226tpu-0 start">
          <li class="c4">
              <span class="c0">S&#39;il est contenu dans une d&eacute;claration de t&eacute;moin et que la collecte est n&eacute;cessaire pour &eacute;valuer, traiter ou r&eacute;gler une r&eacute;clamation d&#39;assurance</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_57e0at34l48-0 start">
          <li class="c4"><span class="c0">Pour identifier les personnes bless&eacute;es, malades ou d&eacute;c&eacute;d&eacute;es et communiquer avec les plus proches parents</span></li>
      </ul>
      <ul class="c5 lst-kix_ahg2hyzcpu9r-0 start">
          <li class="c4"><span class="c0">Si nous avons des motifs raisonnables de croire qu&#39;une personne a &eacute;t&eacute;, est ou pourrait &ecirc;tre victime d&#39;exploitation financi&egrave;re</span></li>
      </ul>
      <ul class="c5 lst-kix_gplzwlej5c86-0 start">
          <li class="c4">
              <span class="c0">
                  S&#39;il est raisonnable de s&#39;attendre &agrave; ce que la collecte et l&#39;utilisation avec consentement compromettent la disponibilit&eacute; ou l&#39;exactitude des renseignements et que la collecte est
                  raisonnable &agrave; des fins li&eacute;es &agrave; une enqu&ecirc;te sur une violation d&#39;un accord ou une contravention aux lois du Canada ou d&#39;une province
              </span>
          </li>
      </ul>
      <ul class="c5 lst-kix_2iedaiphg9vs-0 start">
          <li class="c4">
              <span class="c0">
                  Si la divulgation est requise pour se conformer &agrave; une citation &agrave; compara&icirc;tre, un mandat, une ordonnance du tribunal ou des r&egrave;gles du tribunal relatives &agrave; la production de documents
              </span>
          </li>
      </ul>
      <ul class="c5 lst-kix_9rtd7vtaqx2h-0 start">
          <li class="c4">
              <span class="c0">
                  Si elles ont &eacute;t&eacute; produites par une personne dans le cadre de son emploi, de son entreprise ou de sa profession et que la collecte est conforme aux fins pour lesquelles les informations ont &eacute;t&eacute;
                  produites
              </span>
          </li>
      </ul>
      <ul class="c5 lst-kix_chm5frzcxz27-0 start">
          <li class="c4"><span class="c0">Si la collecte est uniquement &agrave; des fins journalistiques, artistiques ou litt&eacute;raires</span></li>
      </ul>
      <ul class="c5 lst-kix_q8b7ptn8v8mc-0 start">
          <li class="c4"><span class="c0">Si les informations sont accessibles au public et sont sp&eacute;cifi&eacute;es par la r&eacute;glementation</span></li>
      </ul>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c9">4. QUAND ET AVEC QUI </span><span class="c9">PARTAGEONS</span><span class="c0">-NOUS VOS INFORMATIONS PERSONNELLES ?</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c19 c6">Bref: Nous pouvons partager des informations dans des situations sp&eacute;cifiques d&eacute;crites dans cette section et/ou avec les tiers suivants.</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Nous pouvons &ecirc;tre amen&eacute;s &agrave; partager vos informations personnelles dans les situations suivantes&nbsp;:</span></p>
      <ul class="c5 lst-kix_n8fb4y4qxfqe-0 start">
          <li class="c4">
              <span class="c0">
                  Transferts d&#39;entreprise. Nous pouvons partager ou transf&eacute;rer vos informations dans le cadre de ou pendant les n&eacute;gociations de toute fusion, vente d&#39;actifs de la soci&eacute;t&eacute;, financement ou
                  acquisition de tout ou partie de nos activit&eacute;s &agrave; une autre soci&eacute;t&eacute;.
              </span>
          </li>
      </ul>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">5. COMMENT G&Eacute;RONS-NOUS VOS CONNEXIONS SOCIALES&nbsp;?</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c19 c6">
              Bref:Si vous choisissez de vous inscrire ou de vous connecter &agrave; nos Services &agrave; l&#39;aide d&#39;un compte de r&eacute;seau social, nous pouvons avoir acc&egrave;s &agrave; certaines informations vous
              concernant.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c6">
              Nos Services vous offrent la possibilit&eacute; de vous inscrire et de vous connecter en utilisant les d&eacute;tails de votre compte de m&eacute;dias sociaux tiers (comme vos identifiants Facebook ou Twitter). Lorsque vous
              choisissez de le faire, nous
          </span>
          <span class="c6">recevrons</span>
          <span class="c1">
              &nbsp;certaines informations de profil vous concernant de votre fournisseur de m&eacute;dias sociaux. Les informations de profil que nous recevons peuvent varier en fonction du fournisseur de m&eacute;dias sociaux
              concern&eacute;, mais incluront souvent votre nom, votre adresse e-mail, votre liste d&#39;amis et votre photo de profil, ainsi que d&#39;autres informations que vous choisissez de rendre publiques sur une telle plate-forme
              de m&eacute;dias sociaux.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              Nous utiliserons les informations que nous recevons uniquement aux fins d&eacute;crites dans le pr&eacute;sent avis de confidentialit&eacute; ou qui vous sont autrement pr&eacute;cis&eacute;es sur les Services
              concern&eacute;s. Veuillez noter que nous ne contr&ocirc;lons pas et ne sommes pas responsables des autres utilisations de vos informations personnelles par votre fournisseur de m&eacute;dias sociaux tiers. Nous vous
              recommandons de consulter leur avis de confidentialit&eacute; pour comprendre comment ils collectent, utilisent et partagent vos informations personnelles, et comment vous pouvez d&eacute;finir vos pr&eacute;f&eacute;rences
              de confidentialit&eacute; sur leurs sites et applications.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">6. COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS ?</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c19 c6">
              Bref:Nous conservons vos informations aussi longtemps que n&eacute;cessaire pour atteindre les objectifs d&eacute;crits dans le pr&eacute;sent avis de confidentialit&eacute;, sauf disposition contraire de la loi.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              Nous ne conserverons vos informations personnelles que le temps n&eacute;cessaire aux fins &eacute;nonc&eacute;es dans le pr&eacute;sent avis de confidentialit&eacute;, &agrave; moins qu&#39;une p&eacute;riode de
              conservation plus longue ne soit requise ou autoris&eacute;e par la loi (telle que des exigences fiscales, comptables ou autres). Aucun objectif de cet avis ne nous obligera &agrave; conserver vos informations personnelles
              plus longtemps que la p&eacute;riode pendant laquelle les utilisateurs ont un compte chez nous.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              Lorsque nous n&#39;avons aucun besoin commercial l&eacute;gitime de traiter vos informations personnelles, nous supprimerons ou anonymiserons ces informations, ou, si cela n&#39;est pas possible (par exemple, parce que vos
              informations personnelles ont &eacute;t&eacute; stock&eacute;es dans des archives de sauvegarde), nous les supprimerons en toute s&eacute;curit&eacute;. stocker vos informations personnelles et les isoler de tout traitement
              ult&eacute;rieur jusqu&#39;&agrave; ce que la suppression soit possible.
          </span>
      </p>
      <p class="c2"><span class="c0">7. COMMENT GARDONS-NOUS LA S&Eacute;CURIT&Eacute; DE VOS INFORMATIONS&nbsp;?</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c6 c19">Bref:Nous visons &agrave; prot&eacute;ger vos informations personnelles gr&acirc;ce &agrave; un syst&egrave;me de mesures de s&eacute;curit&eacute; organisationnelles et techniques.</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              Nous avons mis en place des mesures de s&eacute;curit&eacute; techniques et organisationnelles appropri&eacute;es et raisonnables con&ccedil;ues pour prot&eacute;ger la s&eacute;curit&eacute; de toute information personnelle
              que nous traitons. Cependant, malgr&eacute; nos mesures de protection et nos efforts pour s&eacute;curiser vos informations, aucune transmission &eacute;lectronique sur Internet ou technologie de stockage d&#39;informations
              ne peut &ecirc;tre garantie &agrave; 100&nbsp;%, nous ne pouvons donc pas promettre ou garantir que les pirates, les cybercriminels ou d&#39;autres tiers non autoris&eacute;s ne seront pas capable de d&eacute;jouer notre
              s&eacute;curit&eacute; et de collecter, acc&eacute;der, voler ou modifier vos informations de mani&egrave;re inappropri&eacute;e. Bien que nous fassions de notre mieux pour prot&eacute;ger vos informations personnelles, la
              transmission d&#39;informations personnelles vers et depuis nos Services est &agrave; vos risques et p&eacute;rils. Vous ne devez acc&eacute;der aux Services que dans un environnement s&eacute;curis&eacute;.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">8. COLLECTONS-NOUS DES INFORMATIONS AUPR&Egrave;S DE MINEURS&nbsp;?</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c19 c6">Bref: Nous ne collectons pas sciemment de donn&eacute;es aupr&egrave;s d&#39;enfants de moins de 18 ans ni ne les commercialisons aupr&egrave;s d&#39;eux.</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c6">
              Nous ne sollicitons pas sciemment des donn&eacute;es ou ne les commercialisons pas aupr&egrave;s d&#39;enfants de moins de 18 ans. En utilisant les Services, vous d&eacute;clarez que vous avez au moins 18 ans ou que vous
              &ecirc;tes le parent ou le tuteur d&#39;un tel mineur et consentez &agrave; ce que ce mineur &agrave; charge utilise les Services. Si nous apprenons que des informations personnelles d&#39;utilisateurs de moins de 18 ans ont
              &eacute;t&eacute; collect&eacute;es, nous
          </span>
          <span class="c6">d&eacute;sactiverons</span>
          <span class="c1">
              &nbsp;le compte et prendrons des mesures raisonnables pour supprimer rapidement ces donn&eacute;es de nos dossiers. Si vous prenez connaissance de donn&eacute;es que nous avons pu recueillir aupr&egrave;s d&#39;enfants de
              moins de 18 ans, veuillez nous contacter &agrave; gsilva@irgt.ca.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">9. QUELS SONT VOS DROITS EN MATI&Egrave;RE DE CONFIDENTIALIT&Eacute;&nbsp;?</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c19 c6">
              Bref: Dans certaines r&eacute;gions, comme le Canada, vous avez des droits qui vous permettent un meilleur acc&egrave;s et un meilleur contr&ocirc;le sur vos informations personnelles. Vous pouvez revoir, modifier ou
              r&eacute;silier votre compte &agrave; tout moment.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              Dans certaines r&eacute;gions (comme le Canada), vous disposez de certains droits en vertu des lois applicables en mati&egrave;re de protection des donn&eacute;es. Ceux-ci peuvent inclure le droit (i) de demander
              l&#39;acc&egrave;s et d&#39;obtenir une copie de vos informations personnelles, (ii) de demander la rectification ou l&#39;effacement&nbsp;; (iii) pour restreindre le traitement de vos informations personnelles ; et (iv) le
              cas &eacute;ch&eacute;ant, &agrave; la portabilit&eacute; des donn&eacute;es. Dans certaines circonstances, vous pouvez &eacute;galement avoir le droit de vous opposer au traitement de vos informations personnelles. Vous
              pouvez faire une telle demande en nous contactant en utilisant les coordonn&eacute;es fournies dans la section &laquo; COMMENT POUVEZ-VOUS NOUS CONTACTER AU SUJET DE CET AVIS ? dessous.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c1">Nous examinerons et agirons sur toute demande conform&eacute;ment aux lois applicables en mati&egrave;re de protection des donn&eacute;es.</span></p>
      <p class="c2"><span class="c1">&nbsp;</span></p>
      <p class="c2">
          <span class="c6">Si vous &ecirc;tes situ&eacute; dans l&#39;EEE ou au Royaume-Uni et que vous pensez que nous traitons </span> <span class="c6">ill&eacute;galement</span>
          <span class="c6">&nbsp;vos informations personnelles, vous avez &eacute;galement le droit de porter plainte aupr&egrave;s de votre</span>
          <span class="c6">
              <a class="c13" href="https://www.google.com/url?q=https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm&amp;sa=D&amp;source=editors&amp;ust=1700238783105353&amp;usg=AOvVaw02sWI20tUkLZDD9-pwwsjo">
                  &nbsp;
              </a>
          </span>
          <span class="c16 c9">
              <a class="c13" href="https://www.google.com/url?q=https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm&amp;sa=D&amp;source=editors&amp;ust=1700238783105622&amp;usg=AOvVaw1zyIXbDyOGkMCiC8CnyKRs">
                  Autorit&eacute; de protection des donn&eacute;es des &Eacute;tats membres
              </a>
          </span>
          <span class="c6">&nbsp;</span><span class="c6">ou</span>
          <span class="c6 c28">
              <a
                  class="c13"
                  href="https://www.google.com/url?q=https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/&amp;sa=D&amp;source=editors&amp;ust=1700238783105811&amp;usg=AOvVaw3LKwBSfFwhfhb46fJpHdov"
              >
                  Autorit&eacute;
              </a>
          </span>
          <span class="c6 c28">
              <a
                  class="c13"
                  href="https://www.google.com/url?q=https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/&amp;sa=D&amp;source=editors&amp;ust=1700238783105961&amp;usg=AOvVaw1E9iYImaej1xwU466jrgJc"
              >
                  &nbsp;britannique de protection des donn&eacute;es
              </a>
          </span>
          <span class="c1">.</span>
      </p>
      <p class="c2">
          <span class="c6">Si vous r&eacute;sidez en Suisse, vous pouvez contacter le</span>
          <span class="c6"><a class="c13" href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home.html&amp;sa=D&amp;source=editors&amp;ust=1700238783106168&amp;usg=AOvVaw0Xg-S9CtdLaBCc3j-35Yi5">&nbsp;</a></span>
          <span class="c16 c9">
              <a class="c13" href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home.html&amp;sa=D&amp;source=editors&amp;ust=1700238783106291&amp;usg=AOvVaw3X7TA7g9SM5Fi0C9lD7aa_">
                  Commissaire f&eacute;d&eacute;ral &agrave; la protection des donn&eacute;es et &agrave; la transparence
              </a>
          </span>
          <span class="c1">.</span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c6 c28">Retrait de votre consentement&nbsp;:</span>
          <span class="c1">
              &nbsp;Si nous nous appuyons sur votre consentement pour traiter vos informations personnelles, qui peut &ecirc;tre un consentement expr&egrave;s et/ou implicite selon la loi applicable, vous avez le droit de retirer votre
              consentement &agrave; tout moment. Vous pouvez retirer votre consentement &agrave; tout moment en nous contactant en utilisant les coordonn&eacute;es fournies dans la section &quot;COMMENT NOUS CONTACTER AU SUJET DE CET AVIS
              ?&quot; dessous.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c0">
              Cependant, veuillez noter que cela n&#39;affectera pas la lic&eacute;it&eacute; du traitement avant son retrait ni, lorsque la loi applicable le permet, le traitement de vos informations personnelles effectu&eacute; sur la
              base de motifs de traitement licites autres que le consentement.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Information sur le compte</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Si vous souhaitez &agrave; tout moment revoir ou modifier les informations de votre compte ou r&eacute;silier votre compte, vous pouvez&nbsp;:</span></p>
      <ul class="c5 lst-kix_rbofihbfns3-0 start">
          <li class="c4"><span class="c0">Connectez-vous aux param&egrave;tres de votre compte et mettez &agrave; jour votre compte utilisateur.</span></li>
      </ul>
      <ul class="c5 lst-kix_x9lvj5bk2pa3-0 start">
          <li class="c4"><span class="c0">Contactez-nous en utilisant les coordonn&eacute;es fournies.</span></li>
      </ul>
      <p class="c2">
          <span class="c9">&Agrave; votre demande de r&eacute;siliation de votre compte, nous </span><span class="c9">d&eacute;sactiverons</span>
          <span class="c0">
              &nbsp;ou supprimerons votre compte et vos informations de nos bases de donn&eacute;es actives. Cependant, nous pouvons conserver certaines informations dans nos fichiers pour pr&eacute;venir la fraude, r&eacute;soudre les
              probl&egrave;mes, aider &agrave; toute enqu&ecirc;te, appliquer nos conditions l&eacute;gales et/ou nous conformer aux exigences l&eacute;gales applicables.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Si vous avez des questions ou des commentaires sur vos droits &agrave; la vie priv&eacute;e, vous pouvez nous envoyer un courriel &agrave; careerswipe@irgt.ca.</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">10. COMMANDES POUR LES FONCTIONS NE PAS SUIVRE</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              La plupart des navigateurs Web et certains syst&egrave;mes d&#39;exploitation mobiles et applications mobiles incluent une fonction ou un param&egrave;tre Do-Not-Track (&quot;DNT&quot;) que vous pouvez activer pour signaler
              votre pr&eacute;f&eacute;rence de confidentialit&eacute; de ne pas faire surveiller et collecter de donn&eacute;es sur vos activit&eacute;s de navigation en ligne. &Agrave; ce stade, aucune norme technologique uniforme pour
              la reconnaissance et la mise en &oelig;uvre des signaux DNT n&#39;a &eacute;t&eacute; finalis&eacute;e. En tant que tel, nous ne r&eacute;pondons pas actuellement aux signaux du navigateur DNT ou &agrave; tout autre
              m&eacute;canisme qui communique automatiquement votre choix de ne pas &ecirc;tre suivi en ligne. Si une norme de suivi en ligne est adopt&eacute;e que nous devons suivre &agrave; l&#39;avenir, nous vous informerons de cette
              pratique dans une version r&eacute;vis&eacute;e de cet avis de confidentialit&eacute;.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">11. LES R&Eacute;SIDENTS DE CALIFORNIE DISPOSENT-ILS DE DROITS SP&Eacute;CIFIQUES &Agrave; LA VIE PRIV&Eacute;E&nbsp;?</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c19 c6">Bref:Oui, si vous r&eacute;sidez en Californie, vous b&eacute;n&eacute;ficiez de droits sp&eacute;cifiques concernant l&#39;acc&egrave;s &agrave; vos informations personnelles.</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              La section 1798.83 du Code civil de Californie, &eacute;galement connue sous le nom de loi &quot;Shine The Light&quot;, permet &agrave; nos utilisateurs r&eacute;sidant en Californie de demander et d&#39;obtenir de nous, une
              fois par an et gratuitement, des informations sur les cat&eacute;gories d&#39;informations personnelles (le cas &eacute;ch&eacute;ant) que nous divulgu&eacute;s &agrave; des tiers &agrave; des fins de marketing direct et les
              noms et adresses de tous les tiers avec lesquels nous avons partag&eacute; des informations personnelles au cours de l&#39;ann&eacute;e civile pr&eacute;c&eacute;dente. Si vous &ecirc;tes un r&eacute;sident de Californie et
              souhaitez faire une telle demande, veuillez nous soumettre votre demande par &eacute;crit en utilisant les coordonn&eacute;es fournies ci-dessous.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              Si vous avez moins de 18 ans, r&eacute;sidez en Californie et disposez d&#39;un compte enregistr&eacute; aupr&egrave;s des Services, vous avez le droit de demander la suppression des donn&eacute;es ind&eacute;sirables que
              vous publiez publiquement sur les Services. Pour demander la suppression de ces donn&eacute;es, veuillez nous contacter en utilisant les coordonn&eacute;es fournies ci-dessous et inclure l&#39;adresse e-mail associ&eacute;e
              &agrave; votre compte et une d&eacute;claration indiquant que vous r&eacute;sidez en Californie. Nous veillerons &agrave; ce que les donn&eacute;es ne soient pas affich&eacute;es publiquement sur les Services, mais sachez
              que les donn&eacute;es peuvent ne pas &ecirc;tre compl&egrave;tement ou compl&egrave;tement supprim&eacute;es de tous nos syst&egrave;mes (par exemple, les sauvegardes, etc.).
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Avis de confidentialit&eacute; de l&#39;ACCP</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c1">Le California Code of Regulations d&eacute;finit un &laquo;&nbsp;r&eacute;sident&nbsp;&raquo; comme suit&nbsp;:</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c1">(1) toute personne qui se trouve dans l&#39;&Eacute;tat de Californie &agrave; des fins autres que temporaires ou transitoires et</span></p>
      <p class="c2"><span class="c1">(2) toute personne domicili&eacute;e dans l&#39;&Eacute;tat de Californie qui se trouve en dehors de l&#39;&Eacute;tat de Californie &agrave; des fins temporaires ou transitoires</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c1">Tous les autres individus sont d&eacute;finis comme des &laquo;&nbsp;non-r&eacute;sidents&nbsp;&raquo;.</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">Si cette d&eacute;finition de &laquo;&nbsp;r&eacute;sident&nbsp;&raquo; s&#39;applique &agrave; vous, nous devons respecter certains droits et obligations concernant vos informations personnelles.</span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2 c3"><span class="c1"></span></p>
      <p class="c2 c3"><span class="c1"></span></p>
      <p class="c2"><span class="c1">Quelles cat&eacute;gories d&#39;informations personnelles collectons-nous ?</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c1">Nous avons collect&eacute; les cat&eacute;gories d&#39;informations personnelles suivantes au cours des douze (12) derniers mois&nbsp;:</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <a id="t.676d247f0432897a19e730412158e36a1dd2c4f9"></a>
      <a id="t.0"></a>
      <table class="c23">
          <tr class="c26">
              <td class="c12" colspan="1" rowspan="1">
                  <p class="c14"><span class="c6">Cat&eacute;gorie</span></p>
              </td>
              <td class="c8" colspan="1" rowspan="1">
                  <p class="c14"><span class="c6">Exemples</span></p>
              </td>
              <td class="c10" colspan="1" rowspan="1">
                  <p class="c14"><span class="c6">Collect&eacute;</span></p>
              </td>
          </tr>
          <tr class="c30">
              <td class="c12" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">A. Identifiants</span></p>
              </td>
              <td class="c8" colspan="1" rowspan="1">
                  <p class="c2">
                      <span class="c1">
                          Coordonn&eacute;es, telles que nom r&eacute;el, alias, adresse postale, num&eacute;ro de t&eacute;l&eacute;phone ou de t&eacute;l&eacute;phone portable, identifiant personnel unique, identifiant en ligne, adresse
                          de protocole Internet, adresse e-mail et nom de compte
                      </span>
                  </p>
              </td>
              <td class="c27" colspan="1" rowspan="1">
                  <p class="c2 c3"><span class="c0"></span></p>
                  <p class="c2"><span class="c1">NON</span></p>
                  <p class="c2 c3"><span class="c0"></span></p>
              </td>
          </tr>
          <tr class="c24">
              <td class="c12" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">B. Cat&eacute;gories d&#39;informations personnelles r&eacute;pertori&eacute;es dans la loi californienne sur les dossiers des clients</span></p>
              </td>
              <td class="c8" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">Nom, coordonn&eacute;es, formation, emploi, ant&eacute;c&eacute;dents professionnels et informations financi&egrave;res</span></p>
              </td>
              <td class="c10" colspan="1" rowspan="1">
                  <p class="c2 c3"><span class="c0"></span></p>
                  <p class="c2"><span class="c1">NON</span></p>
                  <p class="c2 c3"><span class="c0"></span></p>
              </td>
          </tr>
          <tr class="c20">
              <td class="c12" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">C. Caract&eacute;ristiques de classification prot&eacute;g&eacute;es en vertu de la loi californienne ou f&eacute;d&eacute;rale</span></p>
              </td>
              <td class="c8" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">Sexe et date de naissance</span></p>
              </td>
              <td class="c10" colspan="1" rowspan="1">
                  <p class="c2 c3"><span class="c0"></span></p>
                  <p class="c2"><span class="c1">NON</span></p>
                  <p class="c2 c3"><span class="c0"></span></p>
              </td>
          </tr>
          <tr class="c20">
              <td class="c12" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">D. Informations commerciales</span></p>
              </td>
              <td class="c8" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">Informations sur les transactions, historique des achats, d&eacute;tails financiers et informations de paiement</span></p>
              </td>
              <td class="c10" colspan="1" rowspan="1">
                  <p class="c2 c3"><span class="c0"></span></p>
                  <p class="c2"><span class="c1">NON</span></p>
                  <p class="c2 c3"><span class="c0"></span></p>
              </td>
          </tr>
          <tr class="c20">
              <td class="c12" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">E. Informations biom&eacute;triques</span></p>
              </td>
              <td class="c8" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">Empreintes digitales et empreintes vocales</span></p>
              </td>
              <td class="c10" colspan="1" rowspan="1">
                  <p class="c2 c3"><span class="c0"></span></p>
                  <p class="c2"><span class="c1">NON</span></p>
                  <p class="c2 c3"><span class="c0"></span></p>
              </td>
          </tr>
          <tr class="c24">
              <td class="c12" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">F. Internet ou autre activit&eacute; de r&eacute;seau similaire</span></p>
              </td>
              <td class="c8" colspan="1" rowspan="1">
                  <p class="c2">
                      <span class="c1">
                          Historique de navigation, historique de recherche, comportement en ligne, donn&eacute;es d&#39;int&eacute;r&ecirc;t et interactions avec nos sites Web et d&#39;autres, applications, syst&egrave;mes et
                          publicit&eacute;s
                      </span>
                  </p>
              </td>
              <td class="c10" colspan="1" rowspan="1">
                  <p class="c2 c3"><span class="c0"></span></p>
                  <p class="c2"><span class="c1">NON</span></p>
                  <p class="c2 c3"><span class="c0"></span></p>
              </td>
          </tr>
          <tr class="c20">
              <td class="c12" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">G. Donn&eacute;es de g&eacute;olocalisation</span></p>
              </td>
              <td class="c8" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">Emplacement de l&#39;appareil</span></p>
              </td>
              <td class="c10" colspan="1" rowspan="1">
                  <p class="c2 c3"><span class="c0"></span></p>
                  <p class="c2"><span class="c1">NON</span></p>
                  <p class="c2 c3"><span class="c0"></span></p>
              </td>
          </tr>
          <tr class="c20">
              <td class="c12" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">H. Informations sonores, &eacute;lectroniques, visuelles, thermiques, olfactives ou similaires</span></p>
              </td>
              <td class="c8" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">Images et enregistrements audio, vid&eacute;o ou d&#39;appels cr&eacute;&eacute;s dans le cadre de nos activit&eacute;s commerciales</span></p>
              </td>
              <td class="c10" colspan="1" rowspan="1">
                  <p class="c2 c3"><span class="c0"></span></p>
                  <p class="c2"><span class="c1">NON</span></p>
                  <p class="c2 c3"><span class="c0"></span></p>
              </td>
          </tr>
          <tr class="c24">
              <td class="c12" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">I. Informations professionnelles ou relatives &agrave; l&#39;emploi</span></p>
              </td>
              <td class="c8" colspan="1" rowspan="1">
                  <p class="c2">
                      <span class="c1">
                          Coordonn&eacute;es de l&#39;entreprise afin de vous fournir nos services au niveau de l&#39;entreprise ou titre de poste, ant&eacute;c&eacute;dents professionnels et qualifications professionnelles si vous
                          postulez pour un emploi chez nous
                      </span>
                  </p>
              </td>
              <td class="c10" colspan="1" rowspan="1">
                  <p class="c2 c3"><span class="c0"></span></p>
                  <p class="c2"><span class="c1">OUI</span></p>
                  <p class="c2 c3"><span class="c0"></span></p>
              </td>
          </tr>
          <tr class="c20">
              <td class="c12" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">J. Informations sur l&#39;&eacute;ducation</span></p>
              </td>
              <td class="c8" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">Dossiers des &eacute;tudiants et informations sur le r&eacute;pertoire</span></p>
              </td>
              <td class="c10" colspan="1" rowspan="1">
                  <p class="c2 c3"><span class="c0"></span></p>
                  <p class="c2"><span class="c1">OUI</span></p>
                  <p class="c2 c3"><span class="c0"></span></p>
              </td>
          </tr>
          <tr class="c31">
              <td class="c12" colspan="1" rowspan="1">
                  <p class="c2"><span class="c1">K. Inf&eacute;rences tir&eacute;es d&#39;autres informations personnelles</span></p>
              </td>
              <td class="c8" colspan="1" rowspan="1">
                  <p class="c2">
                      <span class="c1">
                          Inf&eacute;rences tir&eacute;es de l&#39;une des informations personnelles collect&eacute;es &eacute;num&eacute;r&eacute;es ci-dessus pour cr&eacute;er un profil ou un r&eacute;sum&eacute; sur, par exemple, les
                          pr&eacute;f&eacute;rences et les caract&eacute;ristiques d&#39;un individu
                      </span>
                  </p>
              </td>
              <td class="c10" colspan="1" rowspan="1">
                  <p class="c2 c3"><span class="c0"></span></p>
                  <p class="c2"><span class="c1">NON</span></p>
                  <p class="c2 c3"><span class="c0"></span></p>
              </td>
          </tr>
          <tr class="c15">
              <td class="c12" colspan="1" rowspan="1">
                  <p class="c14"><span class="c1">L. Informations personnelles sensibles</span></p>
              </td>
              <td class="c8" colspan="1" rowspan="1">
                  <p class="c2 c3"><span class="c1"></span></p>
              </td>
              <td class="c10" colspan="1" rowspan="1">
                  <p class="c2 c3"><span class="c1"></span></p>
                  <p class="c2"><span class="c1">NON</span></p>
                  <p class="c2 c3"><span class="c1"></span></p>
              </td>
          </tr>
      </table>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Nous utiliserons et conserverons les informations personnelles collect&eacute;es selon les besoins pour fournir les Services ou pour&nbsp;:</span></p>
      <ul class="c5 lst-kix_tsvauah56wji-0 start">
          <li class="c4"><span class="c0">Cat&eacute;gorie I - Tant que l&#39;utilisateur a un compte chez nous</span></li>
      </ul>
      <ul class="c5 lst-kix_4nvm8476x3fw-0 start">
          <li class="c4"><span class="c0">Cat&eacute;gorie J - Tant que l&#39;utilisateur a un compte chez nous</span></li>
      </ul>
      <p class="c2">
          <span class="c1">
              Nous pouvons &eacute;galement collecter d&#39;autres informations personnelles en dehors de ces cat&eacute;gories par le biais d&#39;instances o&ugrave; vous interagissez avec nous en personne, en ligne, par
              t&eacute;l&eacute;phone ou par courrier dans le contexte de&nbsp;:
          </span>
      </p>
      <ul class="c5 lst-kix_n4556kd029e3-0 start">
          <li class="c4"><span class="c1">Recevoir de l&#39;aide via nos canaux de support client&nbsp;;</span></li>
      </ul>
      <ul class="c5 lst-kix_sgcirigfztbc-0 start">
          <li class="c4"><span class="c1">Participation &agrave; des sondages ou &agrave; des concours aupr&egrave;s des clients&nbsp;; et</span></li>
      </ul>
      <ul class="c5 lst-kix_38y9gk6whpxu-0 start">
          <li class="c4"><span class="c1">Faciliter la prestation de nos services et r&eacute;pondre &agrave; vos demandes.</span></li>
      </ul>
      <p class="c2"><span class="c1">Comment utilisons-nous et partageons-nous vos informations personnelles ?</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c1">Vous trouverez plus d&#39;informations sur nos pratiques de collecte et de partage de donn&eacute;es dans cet avis de confidentialit&eacute;.</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c6">Vous pouvez nous contacter par courriel &agrave; careerswipe@irgt.ca,</span>
          <span class="c0">ou en vous r&eacute;f&eacute;rant aux coordonn&eacute;es au bas de ce document.</span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              Si vous utilisez un agent autoris&eacute; pour exercer votre droit de retrait, nous pouvons refuser une demande si l&#39;agent autoris&eacute; ne fournit pas la preuve qu&#39;il a &eacute;t&eacute; valablement
              autoris&eacute; &agrave; agir en votre nom.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2"><span class="c1">Vos informations seront-elles partag&eacute;es avec quelqu&#39;un d&#39;autre ?</span></p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              Nous pouvons divulguer vos informations personnelles &agrave; nos prestataires de services conform&eacute;ment &agrave; un contrat &eacute;crit entre nous et chaque prestataire de services. Chaque fournisseur de services est
              une entit&eacute; &agrave; but lucratif qui traite les informations en notre nom, en suivant les m&ecirc;mes obligations strictes de protection de la vie priv&eacute;e impos&eacute;es par le CCPA.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              Nous pouvons utiliser vos informations personnelles &agrave; nos propres fins commerciales, par exemple pour entreprendre des recherches internes pour le d&eacute;veloppement technologique et la d&eacute;monstration. Ceci
              n&#39;est pas consid&eacute;r&eacute; comme une &quot;vente&quot; de vos informations personnelles.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">
              IRGT Advisory Service Inc. n&#39;a divulgu&eacute;, vendu ou partag&eacute; aucune information personnelle &agrave; des tiers &agrave; des fins commerciales ou commerciales au cours des douze (12) mois
              pr&eacute;c&eacute;dents. IRGT Advisory Service Inc. ne vendra ni ne partagera &agrave; l&#39;avenir les informations personnelles appartenant aux visiteurs du site Web, aux utilisateurs et aux autres consommateurs.
          </span>
      </p>
      <p class="c2 c3"><span class="c0"></span></p>
      <p class="c2">
          <span class="c1">Vos droits sur vos donn&eacute;es personnelles</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c18 c6">Droit de demander la suppression des donn&eacute;es &mdash; Demande de suppression</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c1">
              Vous pouvez demander la suppression de vos informations personnelles. Si vous nous demandez de supprimer vos informations personnelles, nous respecterons votre demande et supprimerons vos informations personnelles, sous
              r&eacute;serve de certaines exceptions pr&eacute;vues par la loi, telles que (mais sans s&#39;y limiter) l&#39;exercice par un autre consommateur de son droit &agrave; la libert&eacute; d&#39;expression. , nos exigences de
              conformit&eacute; r&eacute;sultant d&#39;une obligation l&eacute;gale, ou tout traitement pouvant &ecirc;tre n&eacute;cessaire pour se prot&eacute;ger contre des activit&eacute;s ill&eacute;gales.
          </span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c18 c6">Droit d&#39;&ecirc;tre inform&eacute; &mdash; Demande de savoir</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c1">Selon les circonstances, vous avez le droit de savoir :</span>
      </p>
      <ul class="c5 lst-kix_f72iyr12ypi4-0 start">
          <li class="c4">
              <span class="c1">si nous collectons et utilisons vos informations personnelles ;</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_up33wfmi8vcp-0 start">
          <li class="c4">
              <span class="c1">les cat&eacute;gories d&#39;informations personnelles que nous collectons ;</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_e3mwuqkrzl66-0 start">
          <li class="c4">
              <span class="c1">les fins pour lesquelles les renseignements personnels recueillis sont utilis&eacute;s&nbsp;;</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_opoc5mlqz4zq-0 start">
          <li class="c4">
              <span class="c1">si nous vendons ou partageons des informations personnelles avec des tiers&nbsp;;</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_vakfev4c8hy8-0 start">
          <li class="c4">
              <span class="c1">les cat&eacute;gories d&#39;informations personnelles que nous avons vendues, partag&eacute;es ou divulgu&eacute;es &agrave; des fins commerciales&nbsp;;</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_ci3fccjmqu7q-0 start">
          <li class="c4">
              <span class="c1">les cat&eacute;gories de tiers auxquels les informations personnelles ont &eacute;t&eacute; vendues, partag&eacute;es ou divulgu&eacute;es &agrave; des fins commerciales&nbsp;;</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_yz1ucj6qery6-0 start">
          <li class="c4">
              <span class="c1">l&#39;objectif commercial ou commercial de la collecte, de la vente ou du partage d&#39;informations personnelles&nbsp;; et</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_bimel3e4u94r-0 start">
          <li class="c4">
              <span class="c0">les informations personnelles sp&eacute;cifiques que nous avons collect&eacute;es &agrave; votre sujet.</span>
          </li>
      </ul>
      <p class="c2">
          <span class="c1">
              Conform&eacute;ment &agrave; la loi applicable, nous ne sommes pas oblig&eacute;s de fournir ou de supprimer des informations sur les consommateurs qui sont anonymis&eacute;es en r&eacute;ponse &agrave; une demande d&#39;un
              consommateur ou de r&eacute;-identifier des donn&eacute;es individuelles pour v&eacute;rifier une demande d&#39;un consommateur.
          </span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c6 c18">Droit &agrave; la non-discrimination pour l&#39;exercice du droit &agrave; la vie priv&eacute;e d&#39;un consommateur</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c1">Nous ne vous discriminons pas si vous exercez vos droits &agrave; la vie priv&eacute;e.</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c11 c9">Droit de limiter l&#39;utilisation et la divulgation d&#39;informations personnelles sensibles</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">Nous ne traitons pas les informations personnelles sensibles des consommateurs.</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c18 c6">Processus de v&eacute;rification</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c1">
              D&egrave;s r&eacute;ception de votre demande, nous devrons v&eacute;rifier votre identit&eacute; pour d&eacute;terminer que vous &ecirc;tes la m&ecirc;me personne dont nous avons les informations dans notre syst&egrave;me.
              Ces efforts de v&eacute;rification nous obligent &agrave; vous demander de fournir des informations afin que nous puissions les faire correspondre avec les informations que vous nous avez pr&eacute;c&eacute;demment fournies.
              Par exemple, selon le type de demande que vous soumettez, nous pouvons vous demander de fournir certaines informations afin que nous puissions faire correspondre les informations que vous fournissez avec les informations que
              nous avons d&eacute;j&agrave; dans nos dossiers, ou nous pouvons vous contacter via une m&eacute;thode de communication (par exemple, t&eacute;l&eacute;phone ou e-mail) que vous nous avez pr&eacute;c&eacute;demment fournis.
              Nous pouvons &eacute;galement utiliser d&#39;autres m&eacute;thodes de v&eacute;rification selon les circonstances.
          </span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c1">
              Nous n&#39;utilisons les informations personnelles fournies dans votre demande que pour v&eacute;rifier votre identit&eacute; ou votre autorit&eacute; pour faire la demande. Dans la mesure du possible, nous &eacute;viterons
              de vous demander des informations suppl&eacute;mentaires &agrave; des fins de v&eacute;rification. Toutefois, si nous ne pouvons pas v&eacute;rifier votre identit&eacute; &agrave; partir des informations que nous conservons
              d&eacute;j&agrave;, nous pouvons vous demander de fournir des informations suppl&eacute;mentaires aux fins de v&eacute;rification de votre identit&eacute; et &agrave; des fins de s&eacute;curit&eacute; ou de
              pr&eacute;vention des fraudes. Nous supprimerons ces informations suppl&eacute;mentaires fournies d&egrave;s que nous aurons fini de vous v&eacute;rifier.
          </span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c18 c6">Autres droits de confidentialit&eacute;</span>
      </p>
      <ul class="c5 lst-kix_4o4j4vgqvxc8-0 start">
          <li class="c4">
              <span class="c1">Vous pouvez vous opposer au traitement de vos informations personnelles.</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_5h6do6kgex6g-0 start">
          <li class="c4">
              <span class="c1">Vous pouvez demander la correction de vos donn&eacute;es personnelles si elles sont incorrectes ou ne sont plus pertinentes, ou demander de restreindre le traitement des informations.</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_e7tije3qia8m-0 start">
          <li class="c4">
              <span class="c1">
                  Vous pouvez d&eacute;signer un agent autoris&eacute; pour faire une demande en vertu du CCPA en votre nom. Nous pouvons refuser une demande d&#39;un agent autoris&eacute; qui ne fournit pas la preuve qu&#39;il a
                  &eacute;t&eacute; valablement autoris&eacute; &agrave; agir en votre nom conform&eacute;ment au CCPA.
              </span>
          </li>
      </ul>
      <ul class="c5 lst-kix_9rwwduetspl9-0 start">
          <li class="c4">
              <span class="c1">
                  Vous pouvez demander &agrave; vous opposer &agrave; la vente ou au partage futur de vos informations personnelles &agrave; des tiers. D&egrave;s r&eacute;ception d&#39;une demande de retrait, nous donnerons suite
                  &agrave; la demande d&egrave;s que possible, mais au plus tard quinze (15) jours &agrave; compter de la date de soumission de la demande.
              </span>
          </li>
      </ul>
      <p class="c2">
          <span class="c6">Pour exercer ces droits, vous pouvez nous contacter par courriel &agrave; careerswipe@irgt.ca,</span>
          <span class="c0">
              ou en vous r&eacute;f&eacute;rant aux coordonn&eacute;es au bas de ce document. Si vous avez une plainte concernant la fa&ccedil;on dont nous traitons vos donn&eacute;es, nous aimerions avoir de vos nouvelles.
          </span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">12. LES R&Eacute;SIDENTS DE VIRGINIE ONT-ILS DES DROITS SP&Eacute;CIFIQUES &Agrave; LA VIE PRIV&Eacute;E&nbsp;?</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c7">Bref: Oui, si vous r&eacute;sidez en Virginie, vous pouvez b&eacute;n&eacute;ficier de droits sp&eacute;cifiques concernant l&#39;acc&egrave;s et l&#39;utilisation de vos informations personnelles.</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">Avis de confidentialit&eacute; CDPA de Virginie</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">En vertu du Virginia Consumer Data Protection Act (CDPA) :</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">
              &quot;Consommateur d&eacute;signe une personne physique qui est un r&eacute;sident du Commonwealth agissant uniquement dans un contexte individuel ou familial. Il n&#39;inclut pas une personne physique agissant dans un
              contexte commercial ou d&#39;emploi.
          </span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">
              &laquo;&nbsp;Donn&eacute;es personnelles&nbsp;&raquo; d&eacute;signe toute information li&eacute;e ou pouvant raisonnablement &ecirc;tre li&eacute;e &agrave; une personne physique identifi&eacute;e ou identifiable. Les
              &quot;donn&eacute;es personnelles&quot; n&#39;incluent pas les donn&eacute;es anonymis&eacute;es ou les informations accessibles au public.
          </span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">&quot;Vente de donn&eacute;es personnelles&quot; signifie l&#39;&eacute;change de donn&eacute;es personnelles contre une contrepartie mon&eacute;taire.</span>
      </p>
      <p class="c2">
          <span class="c0">Si cette d&eacute;finition &laquo;&nbsp;consommateur&nbsp;&raquo; s&#39;applique &agrave; vous, nous devons respecter certains droits et obligations concernant vos donn&eacute;es personnelles.</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">
              Les informations que nous collectons, utilisons et divulguons &agrave; votre sujet varient en fonction de la mani&egrave;re dont vous interagissez avec IRGT Advisory Service Inc. et nos Services. Pour en savoir plus,
              veuillez visiter les liens suivants :
          </span>
      </p>
      <ul class="c5 lst-kix_odntsbsqj5v-0 start">
          <li class="c4">
              <span class="c0">Donn&eacute;es personnelles que nous collectons</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_e36z24ov5t17-0 start">
          <li class="c4">
              <span class="c0">Comment nous utilisons vos donn&eacute;es personnelles</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_5mpgdy1y7x9f-0 start">
          <li class="c4">
              <span class="c0">Quand et avec qui nous partageons vos donn&eacute;es personnelles</span>
          </li>
      </ul>
      <p class="c2">
          <span class="c11 c9">Vos droits sur vos donn&eacute;es personnelles</span>
      </p>
      <ul class="c5 lst-kix_1gt8f81l3yyi-0 start">
          <li class="c4">
              <span class="c0">Droit d&#39;&ecirc;tre inform&eacute; si nous traitons ou non vos donn&eacute;es personnelles</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_j645kzfgo9y4-0 start">
          <li class="c4">
              <span class="c0">Droit d&#39;acc&egrave;s &agrave; vos donn&eacute;es personnelles</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_h2tpaubf7zlf-0 start">
          <li class="c4">
              <span class="c0">Droit de corriger les inexactitudes dans vos donn&eacute;es personnelles</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_dxq6ite5uvac-0 start">
          <li class="c4">
              <span class="c0">Droit de demander la suppression de vos donn&eacute;es personnelles</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_biy6jdxy3cy-0 start">
          <li class="c4">
              <span class="c0">Droit d&#39;obtenir une copie des donn&eacute;es personnelles que vous avez pr&eacute;c&eacute;demment partag&eacute;es avec nous</span>
          </li>
      </ul>
      <ul class="c5 lst-kix_f29xwxcbx5s4-0 start">
          <li class="c4">
              <span class="c0">
                  Droit de refuser le traitement de vos donn&eacute;es personnelles si elles sont utilis&eacute;es &agrave; des fins de publicit&eacute; cibl&eacute;e, de vente de donn&eacute;es personnelles ou de profilage dans le cadre
                  de d&eacute;cisions produisant des effets juridiques ou similaires (&quot;profilage&quot;)
              </span>
          </li>
      </ul>
      <p class="c2">
          <span class="c0">
              IRGT Advisory Service Inc. n&#39;a vendu aucune donn&eacute;e personnelle &agrave; des tiers &agrave; des fins professionnelles ou commerciales. IRGT Advisory Service Inc. ne vendra pas &agrave; l&#39;avenir de
              donn&eacute;es personnelles appartenant aux visiteurs du site Web, aux utilisateurs et aux autres consommateurs.
          </span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2 c3">
          <span class="c11 c9"></span>
      </p>
      <p class="c2 c3">
          <span class="c11 c9"></span>
      </p>
      <p class="c2">
          <span class="c11 c9">Exercez vos droits pr&eacute;vus par le Virginia CDPA</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">Vous trouverez plus d&#39;informations sur nos pratiques de collecte et de partage de donn&eacute;es dans cet avis de confidentialit&eacute;.</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c9">Vous pouvez nous contacter par courriel &agrave; careerswipe@irgt.ca, en visitant</span>
          <span class="c9">
              <a class="c13" href="https://www.google.com/url?q=https://gocareerswipe.com/&amp;sa=D&amp;source=editors&amp;ust=1700238783127217&amp;usg=AOvVaw1S6Gw8GFNte3ZtUZScnEsk">&nbsp;</a>
          </span>
          <span class="c16 c9">
              <a class="c13" href="https://www.google.com/url?q=https://gocareerswipe.com/&amp;sa=D&amp;source=editors&amp;ust=1700238783127529&amp;usg=AOvVaw04uFztHERZqGbVbBdAPs1x">https://gocareerswipe.com/</a>
          </span>
          <span class="c0">, ou en vous r&eacute;f&eacute;rant aux coordonn&eacute;es au bas de ce document.</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">
              Si vous utilisez un agent autoris&eacute; pour exercer vos droits, nous pouvons refuser une demande si l&#39;agent autoris&eacute; ne fournit pas la preuve qu&#39;il a &eacute;t&eacute; valablement autoris&eacute; &agrave;
              agir en votre nom.
          </span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c9 c11">Processus de v&eacute;rification</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">
              Nous pouvons vous demander de fournir des informations suppl&eacute;mentaires raisonnablement n&eacute;cessaires pour v&eacute;rifier votre demande et celle de votre consommateur. Si vous soumettez la demande par
              l&#39;interm&eacute;diaire d&#39;un agent autoris&eacute;, nous devrons peut-&ecirc;tre collecter des informations suppl&eacute;mentaires pour v&eacute;rifier votre identit&eacute; avant de traiter votre demande.
          </span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">
              D&egrave;s r&eacute;ception de votre demande, nous vous r&eacute;pondrons dans les meilleurs d&eacute;lais, mais dans tous les cas, dans les quarante-cinq (45) jours suivant sa r&eacute;ception. Le d&eacute;lai de
              r&eacute;ponse peut &ecirc;tre prolong&eacute; une fois de quarante-cinq (45) jours suppl&eacute;mentaires lorsque cela est raisonnablement n&eacute;cessaire. Nous vous informerons d&#39;une telle prolongation dans le
              d&eacute;lai de r&eacute;ponse initial de 45 jours, ainsi que de la raison de la prolongation.
          </span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2 c3">
          <span class="c11 c9"></span>
      </p>
      <p class="c2 c3">
          <span class="c11 c9"></span>
      </p>
      <p class="c2">
          <span class="c11 c9">Droit de recours</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c9">Si nous refusons de donner suite &agrave; votre demande, nous vous informerons de notre d&eacute;cision et du raisonnement qui la sous-tend. Si vous souhaitez faire appel de </span>
          <span class="c9">notre</span>
          <span class="c9">
              &nbsp;d&eacute;cision, veuillez nous envoyer un courriel &agrave; careerswipe@irgt.ca. Dans les soixante (60) jours suivant la r&eacute;ception d&#39;un appel, nous vous informerons par &eacute;crit de toute mesure prise ou
              non prise en r&eacute;ponse &agrave; l&#39;appel, y compris une explication &eacute;crite des motifs des d&eacute;cisions. Si votre appel est refus&eacute;, vous pouvez contacter
          </span>
          <span class="c16 c9">
              <a class="c13" href="https://www.google.com/url?q=https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint&amp;sa=D&amp;source=editors&amp;ust=1700238783128624&amp;usg=AOvVaw0qx7zciuKkjQbKemy_UMfD">
                  Le procureur
              </a>
          </span>
          <span class="c9 c16">
              <a class="c13" href="https://www.google.com/url?q=https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint&amp;sa=D&amp;source=editors&amp;ust=1700238783128773&amp;usg=AOvVaw3uGq_ZEucoPeltWKXxnhK_">
                  &nbsp;g&eacute;n&eacute;ral doit porter plainte
              </a>
          </span>
          <span class="c0">.</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">13. FAISONS-NOUS DES MISES &Agrave; JOUR &Agrave; CET AVIS&nbsp;?</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c19 c6">Bref:Oui, nous mettrons &agrave; jour cet avis si n&eacute;cessaire pour rester en conformit&eacute; avec les lois applicables.</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c1">
              Nous pouvons mettre &agrave; jour cet avis de confidentialit&eacute; de temps &agrave; autre. La version mise &agrave; jour sera indiqu&eacute;e par une date de &laquo; r&eacute;vision &raquo; mise &agrave; jour et la
              version mise &agrave; jour entrera en vigueur d&egrave;s qu&#39;elle sera accessible. Si nous apportons des modifications importantes &agrave; cet avis de confidentialit&eacute;, nous pouvons vous en informer soit en
              publiant bien en &eacute;vidence un avis de ces modifications, soit en vous envoyant directement une notification. Nous vous encourageons &agrave; consulter fr&eacute;quemment cet avis de confidentialit&eacute; pour
              &ecirc;tre inform&eacute; de la mani&egrave;re dont nous prot&eacute;geons vos informations.
          </span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">14. COMMENT POUVEZ-VOUS NOUS CONTACTER AU SUJET DE CET AVIS&nbsp;?</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c1">
              Si vous avez des questions ou des commentaires au sujet de cet avis, vous pouvez contacter notre d&eacute;l&eacute;gu&eacute; &agrave; la protection des donn&eacute;es (DPD), Gonzalo Silva, par courriel &agrave;
              gsilva@irgt.ca, par t&eacute;l&eacute;phone au 5149917852, ou nous contacter par courrier &agrave;&nbsp;:
          </span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c1">IRGT Services-conseils inc.</span>
      </p>
      <p class="c2">
          <span class="c1">Gonzalo Silva</span>
      </p>
      <p class="c2">
          <span class="c1">77 Rue des Amadouviers, Sainte-Th&eacute;r&egrave;se, QC J7E 5S7, Canada</span>
      </p>
      <p class="c2">
          <span class="c0">Sainte-Th&eacute;r&egrave;se, Qu&eacute;bec J7E 5S7</span>
      </p>
      <p class="c2">
          <span class="c1">Canada</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c0">15. COMMENT POUVEZ-VOUS EXAMINER, METTRE &Agrave; JOUR OU SUPPRIMER LES DONN&Eacute;ES QUE NOUS COLLECTONS &Agrave; VOTRE SUJET&nbsp;?</span>
      </p>
      <p class="c2 c3">
          <span class="c0"></span>
      </p>
      <p class="c2">
          <span class="c6">
              Sur la base des lois applicables de votre pays, vous pouvez avoir le droit de demander l&#39;acc&egrave;s aux informations personnelles que nous collectons aupr&egrave;s de vous, de modifier ces informations ou de les
              supprimer. Pour demander la r&eacute;vision, la mise &agrave; jour ou la suppression de vos informations personnelles, veuillez visiter&nbsp;:
          </span>
          <span class="c6">
              <a class="c13" href="https://www.google.com/url?q=https://gocareerswipe.com/&amp;sa=D&amp;source=editors&amp;ust=1700238783130343&amp;usg=AOvVaw2hh83xcwItpANHQtCoMNSW">&nbsp;</a>
          </span>
          <span class="c16 c9">
              <a class="c13" href="https://www.google.com/url?q=https://gocareerswipe.com/&amp;sa=D&amp;source=editors&amp;ust=1700238783130508&amp;usg=AOvVaw0BHyp3xK6ttrbbtIzkkEnD">https://gocareerswipe.com/</a>
          </span>
          <span class="c1">.</span>
      </p>
      <p class="c2">
          <span class="c6">Cette politique de confidentialit&eacute; a &eacute;t&eacute; cr&eacute;&eacute;e &agrave; l&#39;aide de </span>
          <span class="c6">Termly&#39;s</span>
          <span class="c6">
              <a class="c13" href="https://www.google.com/url?q=https://termly.io/products/privacy-policy-generator/&amp;sa=D&amp;source=editors&amp;ust=1700238783130872&amp;usg=AOvVaw37hsuMuNJoJsJYPebWDiCq">&nbsp;</a>
          </span>
          <span class="c9 c22">
              <a class="c13" href="https://www.google.com/url?q=https://termly.io/products/privacy-policy-generator/&amp;sa=D&amp;source=editors&amp;ust=1700238783131013&amp;usg=AOvVaw1W9xlpJMaoFwEqxpT6BeQp">
                  G&eacute;n&eacute;rateur de politique de confidentialit&eacute;
              </a>
          </span>
          <span class="c1">.</span>
      </p>
      <p class="c14 c3">
          <span class="c29"></span>
      </p>
  </body>
</html>

  </div>
`;

const PdfPrivacy = () => {
    return (
        <div>
          {/* Renderizar el código HTML */}
          <div dangerouslySetInnerHTML={{ __html: miCodigoHTML }} />
        </div>
      );
}

export default PdfPrivacy
import JobManager from './JobManager';
import { BrowserRouter } from 'react-router-dom';


function App() {
  return (
    <BrowserRouter>
      <JobManager/>   
    </BrowserRouter>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, Navigate  } from "react-router-dom";
import LoginScreen from "./screens/proyect1Screen/LoginScreen";
import CandidateScreen from "./screens/proyect1Screen/CandidateScreen";
import LoginScreen2 from "./screens/proyect2Screen/LoginScreen2";
import CandidateScreen2 from "./screens/proyect2Screen/CandidateScreen2";
import { Layout } from "antd";


//Component
const JobManager = () => {
  //States
  const [user, setUser] = useState<boolean>(false)
  const navigate = useNavigate();


  useEffect(() => {
    
  },[user, navigate])


  return (
    <Routes>
      <Route path="/_en" element={
        <Layout style={{ height: "100vh", width: "100vw" }}>
          <LoginScreen setUser={setUser}/>
        </Layout>
      }/>
      <Route path="/candidates_en/:id" element={
        <Layout style={{ height: "100vh", width: "100vw" }}>
          <CandidateScreen />
        </Layout>
      }/>
      <Route path="/_fr/" element={
        <Layout style={{ height: "100vh", width: "100vw" }}>
          <LoginScreen2 setUser={setUser}/>
        </Layout>
      }/>
      <Route path="/candidates_fr/:id" element={
        <Layout style={{ height: "100vh", width: "100vw" }}>
          <CandidateScreen2 />
        </Layout>
      }/>
     <Route path="/*" element={<Navigate to="/_en" />} />
    </Routes>
  );
};

export default JobManager;
export const candidate_data = [
    {
        id:'1',
        experience:[
            {
                position:'Software Engineer',
                company:'Tech Innovations Inc.',
                date:'March 2018 - June 2021',
                position_desc:"Developed and maintained software applications, collaborated with cross-functional teams, ensured code quality."
            },
            {
                position:'Senior Software Engineer',
                company:'Global Tech Solutions',
                date:'July 2021 - present',
                position_desc:"Lead software development projects, mentored junior engineers, designed scalable software architecture."
            },
            {
                position:'Project Manager',
                company:'Creative Solutions Ltd.',
                date:'January 2016 - February 2018',
                position_desc:"Managed project timelines and budgets, coordinated with stakeholders, oversaw project execution."
            },
        ],
        education:[ {
            major:'Bachelor of Science',
            university:'Tech University',
            date:'2015',
            career:"Web Developer"
        },{
            major:'Master of Science',
            university:'Advanced Tech Institute',
            date:'2017',
            career:"Software Engineering"
        },],
        skills:[
            {
                skill:'React',
                specialization:'5/5'
            },
            {
                skill:'Vue',
                specialization:'2/5'
            },
            {
                skill:'HTML',
                specialization:'4/5'
            },
            {
                skill:'CSS',
                specialization:'3/5'
            },
            {
                skill:'Node.js',
                specialization:'3/5'
            },
        ]
    },
    {
        id:'2',
        experience:[
            {
                position:'Marketing Manager',
                company:'Brand Innovators LLC',
                date:'April 2019 - Present',
                position_desc:"Developed marketing strategies, managed advertising campaigns, analyzed market trends."
            },
            {
                position:'Digital Marketing Specialist',
                company:'Online Marketing Experts',
                date:'May 2016 - March 2019',
                position_desc:"Executed digital marketing campaigns, optimized SEO strategies, managed social media platforms."
            },
            {
                position:'Marketing Coordinator',
                company:'Dynamic Marketing Solutions',
                date:'February 2014 - April 2016',
                position_desc:"Supported marketing team, coordinated marketing events, assisted with campaign analytics."
            },
        ],
        education:[ {
            major:'Bachelor of Arts',
            university:'Business School',
            date:'2013',
            career:"Marketing"
        },{
            major:'Master of Business Administration',
            university:'Global Business University',
            date:'2015',
            career:"Digital Marketing"
        },],
        skills:[
            {
                skill:'React',
                specialization:'5/5'
            },
            {
                skill:'Vue',
                specialization:'2/5'
            },
            {
                skill:'HTML',
                specialization:'4/5'
            },
            {
                skill:'CSS',
                specialization:'3/5'
            },
            {
                skill:'Node.js',
                specialization:'3/5'
            },
        ]
    },{
        id:'3',
        experience:[
            {
                position:'Human Resources Manager',
                company:'People First Corp.',
                date:'August 2020 - Present',
                position_desc:"Led HR initiatives, managed recruitment processes, implemented employee development programs."
            },
            {
                position:'HR Business Partner',
                company:'Innovative Enterprises',
                date:'March 2017 - July 2020',
                position_desc:"Advised on HR strategies, supported departmental managers, facilitated employee relations."
            },
            {
                position:'Recruitment Specialist',
                company:'Talent Solutions Ltd.',
                date:'June 2014 - February 2017',
                position_desc:"Managed full-cycle recruitment, developed talent acquisition strategies, conducted interviews."
            },
        ],
        education:[ {
            major:'Bachelor of Science',
            university:'Humanities University',
            date:'2013',
            career:"Human Resources Management"
        },{
            major:'Master of Science',
            university:'Social Sciences Academy',
            date:'2015',
            career:"Organizational Psychology"
        },],
        skills:[
            {
                skill:'React',
                specialization:'5/5'
            },
            {
                skill:'Vue',
                specialization:'2/5'
            },
            {
                skill:'HTML',
                specialization:'4/5'
            },
            {
                skill:'CSS',
                specialization:'3/5'
            },
            {
                skill:'Node.js',
                specialization:'3/5'
            },
        ]
    }
]
